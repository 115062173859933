import { sectionDataAdapter } from "./sectionDataAdapter";
import { cardsDataItemNormalize } from "./sectionCardImageAdapter";
import { buttonsAdapter } from "./buttonsAdapter";

export function sectionStepCardAdapter(card) {
  const {
    title,
    titleColor,
    icon: [{ url: icon = '' } = {}] = [],
    backgroundColor,
    description,
    descriptionColor,
    iconText,
    contentAlignment,
    actionButtons,
  } = cardsDataItemNormalize(card)
  return {
    title,
    titleColor,
    icon,
    iconText,
    backgroundColor,
    description,
    descriptionColor,
    contentAlignment,
    actionButtons: buttonsAdapter(actionButtons),
  }
}

export function sectionStepsAdapter(data) {
  let cols: number[] = []
  if (data.cols) {
    cols = data.cols.split(',').map((colNumber) => JSON.parse(colNumber))
  } else {
    cols = data.cards.map(() => 12 / data.cards.length)
  }
  return {
    sectionData: sectionDataAdapter(data),
    cards: data.cards.map(sectionStepCardAdapter),
    actionButtons: buttonsAdapter(data.actionButtons),
    backgroundColor: data.backgroundColor,
    arrows: data.arrows === 'Да',
    cols,
  }
}
