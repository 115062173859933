// @ts-nocheck
import { propertyValuesNormalizer } from '../pageAdapter'
import { Element } from '../../composition-functions'
import { setISOFormat } from '../../../helpers/convertDateTimezone'
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionIframeAdapter = (data: any) => {
  const {
    iframeData,
    plugDateOn,
    plugDateOff,
    plugTitle,
    plugText,
    promo = [],
    clearPage,
  } = data

  return {
    sectionData: sectionDataAdapter({
      ...data,
      sectionMainTitle: data.title,
    }),
    iframeData,
    plugData: {
      plugDateOn: setISOFormat(plugDateOn),
      plugDateOff: setISOFormat(plugDateOff),
      plugTitle,
      plugText,
    },
    clearPage: clearPage === 'ДА',
    promoCodes: promo.map((item: Element) => {
      const { code, name, propertyValues, propertyValuesEnum } = item
      const { startDatePromo, endDatePromo } =
        propertyValuesNormalizer(propertyValues)
      return {
        name,
        code,
        ...propertyValuesNormalizer(propertyValues),
        ...propertyValuesNormalizer(propertyValuesEnum),
        startDatePromo: setISOFormat(startDatePromo),
        endDatePromo: setISOFormat(endDatePromo),
      }
    }),
  }
}
