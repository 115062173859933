import { sectionDataAdapter } from './sectionDataAdapter'
import { cardsDataItemNormalize } from './sectionCardImageAdapter'
import { buttonsAdapter } from './buttonsAdapter'

export function sectionCardsTitleDescriptionImageCardAdapter(card) {
  const {
    title,
    titleColor,
    actionButtons,
    image: [{ url: image = '' } = {}] = [],
    backgroundColor,
    description,
    descriptionColor,
    imageHeightMobile,
  } = cardsDataItemNormalize(card)
  return {
    title,
    titleColor,
    actionButtons: buttonsAdapter(actionButtons),
    image,
    backgroundColor,
    description,
    descriptionColor,
    imageHeightMobile: imageHeightMobile ? Number(imageHeightMobile) : 240,
  }
}

export function sectionCardsTitleDescriptionImageAdapter(data) {
  let cols: number[] = []
  if (data.cols) {
    cols = data.cols.split(',').map((colNumber) => JSON.parse(colNumber))
  } else {
    cols = data.cards.map(() => 12 / data.cards.length)
  }
  return {
    sectionData: sectionDataAdapter(data),
    cards: data.cards.map(sectionCardsTitleDescriptionImageCardAdapter),
    cols,
  }
}
