// @ts-nocheck
import {
  propertyValuesRelationNormalizer,
  propertyValuesNormalizer,
} from '../pageAdapter'
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionCardRatingAdapter = (data: any) => {
  const { list: [{ propertyValuesRelation = [] } = {}] = [] } = data
  const value = propertyValuesRelationNormalizer(propertyValuesRelation)
  const list = value.list.map((item) =>
    propertyValuesNormalizer(item.propertyValues)
  )
  return {
    sectionData: sectionDataAdapter(data),
    list,
  }
}
