// @ts-nocheck
import { listItemNormalize, normalizeProps } from './sectionListAdapter'
import { documentListItemNormalize } from './sectionDocumentsListAdapter'
import { Element } from '@/apollo/composition-functions'

export const sectionRequisitesAdapter = (data: any) => {
  const { isReverse, title, lastModified, list, buttonText, document, sectionId } = data
  let docUrl = null
  if (document && document[0]) {
    const [doc] = document
    docUrl = documentListItemNormalize(doc).url
  }
  const newList = list.map((item: Element) => {
    const { list, title, text, linkText, linkUrl } = {
      ...listNormalizeReq(item),
    }

    return {
      title,
      text,
      link: { linkText, linkUrl, arrowType: 'arrow-right' },
      list: list.map((item: Element) => listItemNormalize(item)),
    }
  })
  return {
    list: newList,
    title,
    isReverse: isReverse === 'Да',
    lastModified,
    sectionData: {
      sectionId,
    },
    buttonText,
    docUrl,
  }
}

export const listNormalizeReq = (listItem: Element) => {
  const { propertyValues, propertyValuesRelation, propertyValuesEnum } =
    listItem
  const {
    title = '',
    text = '',
    linkText = '',
    linkUrl = '',
    list = [],
  } = {
    ...normalizeProps(
      propertyValues,
      propertyValuesRelation,
      propertyValuesEnum
    ),
  }
  return { title, list, text, linkText, linkUrl }
}
