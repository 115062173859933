// @ts-nocheck
import {
  propertyValuesRelationNormalizer,
  propertyValuesNormalizer,
} from '../pageAdapter'
import { searchMime } from './sectionDocumentsListAdapter'
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionListArchiveAdapter = (data: any) => {
  const {
    title,
    lastModified,
    list: [{ propertyValuesRelation = [], propertyValuesEnum = [] } = {}] = [],
  } = data
  const { list = [] } = propertyValuesRelationNormalizer(propertyValuesRelation)
  const { reverse } = propertyValuesNormalizer(propertyValuesEnum)
  const mappedList = list
    .filter(({ activeFrom }) => activeFrom)
    .map(
      ({
        propertyValues,
        propertyValuesRelation,
        activeFrom: _activeFrom,
        id,
      }) => {
        const mappedItem = propertyValuesNormalizer(propertyValues)
        const mappedItemRelation = propertyValuesRelationNormalizer(
          propertyValuesRelation
        )
        const activeFrom = _activeFrom && new Date(_activeFrom)
        const {
          document: [
            { url: fileUrl = '', size: fileSize = '', contentType = '' } = {},
          ] = [],
        } = mappedItemRelation

        const kb = 1024
        const mb = 1024 * 1024
        const kbSize = `${(fileSize / kb).toFixed(1)} Kb`
        const mbSize = `${(fileSize / mb).toFixed(1)} Mb`
        const rightSize =
          fileSize > mb ? mbSize : fileSize > kb ? kbSize : kbSize
        const { title, description, dateText, regNumber } = mappedItem

        const day = activeFrom.getDate()
        const month = activeFrom.getMonth() + 1
        const year = activeFrom.getFullYear()

        return {
          id,
          title,
          regNumber,
          dateText,
          description,
          url: fileUrl,
          pdfWeight: rightSize,
          contentType: searchMime(contentType, fileUrl),
          published: {
            year,
            month,
            day,
          },
        }
      }
    )

  return {
    sectionData: sectionDataAdapter(data),
    data: {
      title,
      lastModified,
      list: reverse ? mappedList.reverse() : mappedList,
    },
  }
}
