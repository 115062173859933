// @ts-nocheck
import { buttonsAdapter } from './'

export const sectionProductHeroAdapter = (data: any) => {
  const { isHeroAbout } = data
  let newData
  if (isHeroAbout === 'Да') {
    const {
      additionalText,
      isMobImageHidden,
      title,
      buttonActions,
      text,
      imageCol,
      roundImage,
      videoLink,
      imageDesktop: [{ url: imageDesktop = null } = {}] = [{}],
      imageMobile: [{ url: imageMobile = null } = {}] = [{}],
      alt = '',
    } = data
    newData = {
      additionalText,
      isMobImageHidden: isMobImageHidden === 'Да',
      roundImage: roundImage === 'Да',
      title,
      text,
      videoLink,
      imageDesktop,
      imageMobile,
      imageCol: imageCol ? Number.parseInt(imageCol) : 7,
      buttonActions: buttonsAdapter(buttonActions),
      isHeroAbout: true,
      alt,
    }
  } else {
    const {
      additionalText,
      isMobImageHidden,
      title,
      text,
      videoLink,
      roundImage,
      chipText,
      buttonActions,
      imageCol,
      image: [{ url: image }] = [{}],
      alt = '',
    } = data
    newData = {
      additionalText,
      isMobImageHidden: isMobImageHidden === 'Да',
      roundImage: roundImage === 'Да',
      chipText,
      title,
      text,
      videoLink,
      image,
      imageCol: imageCol ? Number.parseInt(imageCol) : 7,
      buttonActions: buttonsAdapter(buttonActions),
      isHeroAbout: false,
      alt,
    }
  }
  return {
    ...newData,
  }
}
