// @ts-nocheck
import { listItemNormalize } from "./sectionListAdapter";
import { officeTypeInfoAdapter } from "./officeTypeInfoAdapter";
import { propertyValuesRelationNormalizer } from "../pageAdapter";
import { AdaptedOffice } from "@/apollo/adapters/modules/officeAdapter";
import { getDistanceFromLatLonInKm } from "@/helpers/distanceCalculation";

export const sectionFindNearestOfficeAdapter = (data: any) => {
  const {
    notification = '',
    certifiedAgentCard = [],
    incidentOfficeFilterText = '',
    incidentOfficeFilterIcon = [],
    agentsSuggestionsEmptyText = '',
    officesSuggestionsEmptyText = '',
    suggestionsErrorText = '',
    incidentFilterActiveInfoText = '',
    incidentFilterActiveInfoIcon = [],
    successSubmitToAgentText = '',
    backButtonLink = '/'
  } = data
  return {
    certifiedAgentCard: certifiedAgentCard.map((item) => {
      return {
        name: item.name,
        ...listItemNormalize(item),
      }
    }),
    officesSuggestionsEmptyText,
    agentsSuggestionsEmptyText,
    suggestionsErrorText,
    incidentFilterActiveInfoText,
    successSubmitToAgentText,
    notificationText: notification,
    incidentOfficeFilterText,
    incidentOfficeFilterIcon: incidentOfficeFilterIcon[0]?.url,
    incidentFilterActiveInfoIcon: incidentFilterActiveInfoIcon[0]?.url,
    backButtonLink,
  }
}
