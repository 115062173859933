// @ts-nocheck
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionFeedbackDebtorsAdapter = (data: any) => {
  const {
    sectionFormTitle,
    sectionFormDescription,
    sectionMemoText,
    successMessage,
  } = data
  return {
    sectionData: {
      ...sectionDataAdapter(data),
      sectionFormTitle,
      sectionFormDescription,
      sectionMemoText,
      successMessage,
    },
    rgsMails: {
      CLAIM: data.CLAIM,
      OTHER: data.OTHER,
      COURT: data.COURT,
      EXECUTION_COURT: data.EXECUTION_COURT,
    },
  }
}
