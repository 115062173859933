import { sectionDataAdapter } from './sectionDataAdapter'

export function sectionOsagoHeroAdapter(data) {
  const {
    image: [{ url: image = '' } = {}] = [],
    badgeIcon: [{ url: badgeIcon = '' } = {}] = [],
  } = data
  return {
    sectionData: sectionDataAdapter(data),
    description: data.description || '',
    iframe: data.iframe || '',
    image,
    badgeIcon,
    badgeText: data.badgeText,
  }
}
