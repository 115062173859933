import {
  NormalizedPageData,
  AdaptedPage,
  AdaptedComponent,
  SectionData,
  PropertyEnumWithDescription,
} from '@/contracts/Page'
import {
  Element,
  PropertyRelation,
  PropertyValue,
  PropertyEnum,
  PropertyType,
} from '../composition-functions'
import { AdaptedPageItem } from '@/contracts/Settings'
import {
  sectionBlockBannerAdapter,
  sectionBannerAdapter,
  sectionCardIconAdapter,
  sectionCardImageAdapter,
  sectionFaqAdapter,
  sectionBannerListAdapter,
  sectionCardImageIconAdapter,
  sectionLinksAdapter,
  sectionCardRatingAdapter,
  sectionCheckBlanksAdapter,
  sectionImagesAdapter,
  sectionTextAdapter,
  sectionGridAdapter,
  sectionIframeAdapter,
  sectionFaqNavigationAdapter,
  sectionProductHeroAdapter,
  sectionFormAdapter,
  sectionLeadDetailAdapter,
  sectionListAdapter,
  sectionListExtendedAdapter,
  sectionHeroSliderAdapter,
  sectionLeadsAdapter,
  sectionSubscribeAdapter,
  sectionRequisitesAdapter,
  sectionDocumentsListAdapter,
  sectionDocumentsAdapter,
  sectionCardImageGridAdapter,
  sectionBlockTagRowAdapter,
  sectionListArchiveAdapter,
  sectionNewsAdapter,
  sectionCardNewsAdapter,
  sectionSearchAdapter,
  sectionListAlphabetAdapter,
  sectionInstructionsTestAdapter,
  sectionListBlockLinksAdapter,
  sectionCardsInfoAdapter,
  sectionCheckDocumentsAdapter,
  sectionCheckAgentsAdapter,
  sectionCheckStatusAdapter,
  sectionAppealAdapter,
  sectionFeedbackLKAdapter,
  sectionPretrialAdapter,
  sectionInsuranceCaseAdapter,
  sectionOfficesAdapter,
  sectionListOfficesAdapter,
  sectionFeedbackDebtorsAdapter,
  sectionPopularAdapter,
  sectionCustomAdapter,
  sectionChildrenSportAdapter,
  sectionReviewsAdapter,
  sectionAchievementsAdapter,
  sectionImageTextCardAdapter,
  sectionCardsTitleAndIconAdapter,
  sectionAgentsFeedbackAdapter,
  sectionCardsTitleDescriptionImageAdapter,
  sectionStepsAdapter,
  sectionGridAdvantagesAdapter,
  sectionOsagoHelpAdapter,
  sectionOsagoNumberAdapter,
  sectionOsagoInfoAdapter,
  sectionOsagoHeroAdapter,
  sectionTabsAdapter,
  sectionButtonsAdapter,
  sectionTextActionsAdapter,
  sectionPulseGridAdapter,
  sectionImageBackgroundAdapter,
  sectionPulseFormAdapter,
  sectionAlternateSliderAdapter,
  sectionServicesAdapter,
  sectionPromocodeBanner,
  sectionSearchBotAdapter,
  sectionCardsAdvantagesAdapter,
  sectionCallToActionAdapter,
  sectionFormCertificatePaymentAdapter,
  sectionFormFeedbackNewAdapter,
  sectionSupportAdapter,
  sectionShipPolicyCheckAdapter,
  sectionFindNearestOfficeAdapter,
} from './modules'
import { sectionVacanciesHeroAdapter } from './modules/sectionVacanciesHeroAdapter'
import { sectionSearchVacanciesAdapter } from './modules/sectionSearchVacanciesAdapter'
import { sectionVacanciesListAdapter } from './modules/sectionVacanciesListAdapter'
import { sectionImageTextAdapter } from './modules/sectionImageTextAdapter'
import { sectionCardsNumberAdapter } from './modules/sectionCardsNumberAdapter'
import { sectionVideoAdapter } from './modules/sectionVideoAdapter'
import { sectionListTablesAdapter } from './modules/sectionListTablesAdapter'
import { sectionSTOAAdapter } from './modules/sectionSTOAAdapter'
import { sectionQuizAdapter } from './modules/sectionQuizAdapter'
import { sectionHeroVipAdapter } from './modules/sectionHeroVipAdapter'
import { setISOFormat } from '../../helpers/convertDateTimezone'
import { FAQMicrodata } from '../../helpers/head'

/** адаптер для сущности страницы */
export const pageAdapter = (
  pages: Element[],
  pagesList: AdaptedPageItem[]
): AdaptedPage => {
  const [page] = pages || [{}]
  const {
    code,
    id,
    name,
    propertyValuesRelation = [],
    propertyValues = [],
    propertyValuesEnum = [],
  } = page

  const {
    components = [],
    seoImage: [{ url: seoImage = '' }] = [{ url: '' }],
    metaTags = [],
    seoFields: [
      {
        propertyValues: seoFieldsPropertyValues,
        propertyValuesRelation: seoFieldsPropertyValuesRelation,
      },
    ] = [{ propertyValues: [], propertyValuesRelation: [] }],
    notificationIcon: [{ url: notificationIcon }] = [{}],
  } = propertyValuesRelationNormalizer(propertyValuesRelation)

  const adaptedComponents = sectionNormalizer(components)

  const {
    seoTitle = '',
    seoDescription = '',
    seoSchema = '',
    metaName = '',
    seoSiteName = '',
    metaContent = '',
    canonicalHref = '',
    notificationContent,
    notificationBackgroundColor,
    notificationContentColor,
    notificationStartTime = null,
    notificationEndTime = null,
  } = propertyValuesNormalizer(propertyValues)

  const heroComponent = adaptedComponents.find(
    (component) =>
      component?.type === 'sectionProductHero' ||
      component?.type === 'sectionHeroVip'
  )
  const heroSeoImage =
    heroComponent &&
    (heroComponent?.value?.image || heroComponent?.value?.imageDesktop)

  const {
    notificationActivity,
    hideInDropdownMenu,
    hideBreadcrumbs,
    hideDelimiters,
    hideNavigation,
  } = propertyValuesNormalizer(propertyValuesEnum)

  const faqComponents = adaptedComponents.filter(
    (component) => component.type === 'sectionFaq'
  )

  const FAQSeoSchema: string = FAQMicrodata(faqComponents)

  const seoFields = seoFieldsPropertyValues
    ? propertyValuesNormalizer(seoFieldsPropertyValues)
    : []
  const currentPage = pagesList.find((pageItem) => pageItem.id === page.id)
  const ROUTES: AdaptedPageItem[] = []
  const getRoutes = (el: AdaptedPageItem) => {
    if (el && el.parent) {
      const parent = pagesList.find((pageItem) => pageItem.id === el.parent)
      if (parent) {
        if (!parent.hideInDropdownMenu) ROUTES.unshift(parent)
        getRoutes(parent)
      }
    }
  }
  if (currentPage) {
    getRoutes(currentPage)
    ROUTES.push(currentPage)
  }
  return {
    seo: {
      title: seoTitle,
      description: seoDescription,
      image: heroSeoImage || seoImage,
      schema: [seoSchema, FAQSeoSchema],
      siteName: seoSiteName,
      metaName,
      metaContent,
      canonicalHref,
    },
    seoFields,
    seoFieldsPropertyValuesRelation,
    hideBreadcrumbs: hideBreadcrumbs === 'Да',
    hideInDropdownMenu: hideInDropdownMenu === 'Да',
    hideDelimiters: hideDelimiters === 'Да',
    hideNavigation,
    notification: {
      notificationContent: notificationContent,
      notificationStartTime: setISOFormat(notificationStartTime),
      notificationEndTime: setISOFormat(notificationEndTime),
      notificationActivity: notificationActivity === 'ДА',
      notificationBackgroundColor,
      notificationContentColor,
      notificationIcon,
    },
    code,
    id,
    name,
    components: adaptedComponents,
    route: ROUTES,
  }
}

/** разводящий адаптер для секций */
export const sectionNormalizer = (sections: Element[]): AdaptedComponent[] => {
  return sections
    .filter((section) => !!section)
    .map((section) => {
      const {
        code,
        id,
        name,
        sort,
        propertyValues,
        propertyValuesRelation,
        propertyValuesEnum,
        iblock,
      } = section
      let { code: type } = iblock || {}
      const value = {
        ...propertyValuesNormalizer(propertyValues || []),
        ...propertyValuesRelationNormalizer(propertyValuesRelation || []),
        ...propertyValuesNormalizer(propertyValuesEnum || []),
      }

      let sectionData: SectionData = {}

      switch (type) {
        case 'sectionQuiz':
          sectionData = sectionQuizAdapter(value)
          break
        case 'sectionSTOA':
          sectionData = sectionSTOAAdapter(value)
          break
        case 'sectionHeroVip':
          sectionData = sectionHeroVipAdapter(value)
          break
        case 'sectionListTables':
          sectionData = sectionListTablesAdapter(value)
          break
        case 'sectionVideo':
          sectionData = sectionVideoAdapter(value)
          break
        case 'sectionVacanciesList':
          sectionData = sectionVacanciesListAdapter(value)
          break
        case 'sectionCardsNumber':
          sectionData = sectionCardsNumberAdapter(value)
          break
        case 'sectionImageText':
          sectionData = sectionImageTextAdapter(value)
          break
        case 'sectionVacanciesHero':
          sectionData = sectionVacanciesHeroAdapter(value)
          break
        case 'sectionSearchVacanciesStartCareer':
          sectionData = sectionSearchVacanciesAdapter(value)
          break
        case 'sectionSearchVacancies':
          sectionData = sectionSearchVacanciesAdapter(value)
          break
        case 'sectionBanner':
          sectionData = sectionBannerAdapter(value)
          break
        case 'sectionCardIcon':
          sectionData = sectionCardIconAdapter(value)
          break
        case 'sectionCardImage':
          sectionData = sectionCardImageAdapter(value)
          break
        case 'sectionBannerList':
          sectionData = sectionBannerListAdapter(value)
          break
        case 'sectionCardImageIcon':
          sectionData = sectionCardImageIconAdapter(value)
          break
        case 'sectionLinks':
          sectionData = sectionLinksAdapter(value)
          break
        case 'sectionCardRating':
          sectionData = sectionCardRatingAdapter(value)
          break
        case 'sectionImages':
          sectionData = sectionImagesAdapter(value)
          break
        case 'sectionFaq':
          sectionData = sectionFaqAdapter(value)
          break
        case 'sectionProductHero':
          sectionData = sectionProductHeroAdapter(value)
          break
        case 'sectionText':
          sectionData = sectionTextAdapter(value)
          break
        case 'sectionGrid':
          sectionData = sectionGridAdapter(value)
          break
        case 'sectionIframe':
          sectionData = sectionIframeAdapter(value)
          break
        case 'sectionFaqNavigation':
          sectionData = sectionFaqNavigationAdapter(value)
          break
        case 'sectionForm':
          sectionData = sectionFormAdapter(value)
          break
        case 'sectionLeadDetail':
          sectionData = sectionLeadDetailAdapter(value)
          break
        case 'sectionBlockBanner':
          sectionData = sectionBlockBannerAdapter(value)
          break
        case 'sectionList':
          sectionData = sectionListAdapter(value)
          break
        case 'sectionListExtended':
          sectionData = sectionListExtendedAdapter(value, true)
          break
        case 'sectionLeads':
          sectionData = sectionLeadsAdapter(value)
          break
        case 'sectionHeroSlider':
          sectionData = sectionHeroSliderAdapter(value)
          break
        case 'sectionSubscribe':
          sectionData = sectionSubscribeAdapter(value)
          break
        case 'sectionRequisites':
          sectionData = sectionRequisitesAdapter(value)
          break
        case 'sectionDocumentsList':
          sectionData = sectionDocumentsListAdapter(value)
          break
        case 'sectionDocuments':
          sectionData = sectionDocumentsAdapter(value)
          break
        case 'sectionCardImageGrid':
          sectionData = sectionCardImageGridAdapter(value)
          break
        case 'sectionBlockTagRow':
          sectionData = sectionBlockTagRowAdapter(value)
          break
        case 'sectionListArchive':
          sectionData = sectionListArchiveAdapter(value)
          break
        case 'sectionAllNews':
          sectionData = sectionNewsAdapter(value)
          break
        case 'sectionInstructionsTest':
          sectionData = sectionInstructionsTestAdapter(value)
          break
        case 'sectionCardNews':
          sectionData = sectionCardNewsAdapter(value)
          break
        case 'sectionSearch':
          sectionData = sectionSearchAdapter(value)
          break
        case 'sectionListAlphabet':
          sectionData = sectionListAlphabetAdapter(value)
          break
        case 'sectionListBlockLinks':
          sectionData = sectionListBlockLinksAdapter(value)
          break
        case 'sectionCardsInfo':
          sectionData = sectionCardsInfoAdapter(value)
          break
        case 'sectionCheckBlanks':
          sectionData = sectionCheckBlanksAdapter(value)
          break
        case 'sectionCheckDocuments':
          sectionData = sectionCheckDocumentsAdapter(value)
          break
        case 'sectionCheckAgents':
          sectionData = sectionCheckAgentsAdapter(value)
          break
        case 'sectionCheckStatus':
          sectionData = sectionCheckStatusAdapter(value)
          break
        case 'sectionAppeal':
          sectionData = sectionAppealAdapter(value)
          break
        case 'sectionPretrial':
          sectionData = sectionPretrialAdapter(value)
          break
        case 'sectionFeedbackLK':
          sectionData = sectionFeedbackLKAdapter(value)
          break
        case 'sectionFeedbackDebtors':
          sectionData = sectionFeedbackDebtorsAdapter(value)
          break
        case 'sectionCustom':
          sectionData = sectionCustomAdapter(value)
          break
        case 'sectionAchievements':
          sectionData = sectionAchievementsAdapter(value)
          break
        case 'sectionInsuranceCase':
          sectionData = sectionInsuranceCaseAdapter(value)
          break
        case 'sectionOffices':
          sectionData = sectionOfficesAdapter(value)
          break
        case 'sectionListOffices':
          sectionData = sectionListOfficesAdapter(value)
          break
        case 'sectionPopular':
          sectionData = sectionPopularAdapter(value)
          break
        case 'sectionChildrenSport':
          sectionData = sectionChildrenSportAdapter(value)
          break
        case 'sectionReviews':
          sectionData = sectionReviewsAdapter(value)
          break
        case 'sectionImageTextCard':
          sectionData = sectionImageTextCardAdapter(value)
          break
        case 'sectionCardsTitleAndIcon':
          sectionData = sectionCardsTitleAndIconAdapter(value)
          break
        case 'sectionCardsTitleDescriptionImage':
          sectionData = sectionCardsTitleDescriptionImageAdapter(value)
          break
        case 'sectionSteps':
          sectionData = sectionStepsAdapter(value)
          break
        case 'sectionAgentsFeedback':
          sectionData = sectionAgentsFeedbackAdapter(value)
          break
        case 'sectionGridAdvantages':
          sectionData = sectionGridAdvantagesAdapter(value)
          break
        case 'sectionOsagoHelp':
          sectionData = sectionOsagoHelpAdapter(value)
          break
        case 'sectionOsagoNumber':
          sectionData = sectionOsagoNumberAdapter(value)
          break
        case 'sectionOsagoInfo':
          sectionData = sectionOsagoInfoAdapter(value)
          break
        case 'sectionOsagoHero':
          sectionData = sectionOsagoHeroAdapter(value)
          break
        case 'sectionTabs':
          sectionData = sectionTabsAdapter(value)
          break
        case 'sectionButtons':
          sectionData = sectionButtonsAdapter(value)
          break
        case 'sectionTextActions':
          sectionData = sectionTextActionsAdapter(value)
          break
        case 'sectionPulseGrid':
          sectionData = sectionPulseGridAdapter(value)
          break
        case 'sectionImageBackground':
          sectionData = sectionImageBackgroundAdapter(value)
          break
        case 'sectionPulseForm':
          sectionData = sectionPulseFormAdapter(value)
          break
        case 'sectionAlternateSlider':
          sectionData = sectionAlternateSliderAdapter(value)
          break
        case 'sectionServices':
          sectionData = sectionServicesAdapter(value)
          break
        case 'sectionPromocodeBanner':
          sectionData = sectionPromocodeBanner(value)
          break
        case 'sectionSearchBot':
          sectionData = sectionSearchBotAdapter(value)
          break
        case 'sectionCallToAction':
          sectionData = sectionCallToActionAdapter(value)
          break
        case 'sectionCardsAdvantages':
          sectionData = sectionCardsAdvantagesAdapter(value)
          break
        case 'sectionFormCertificatePayment':
          sectionData = sectionFormCertificatePaymentAdapter(value)
          break
        case 'sectionFormFeedbackNew':
          sectionData = sectionFormFeedbackNewAdapter(value)
          break
        case 'sectionSupport':
          sectionData = sectionSupportAdapter(value)
          break
        case 'sectionFindNearestOffice':
          sectionData = sectionFindNearestOfficeAdapter(value)
          break
        case 'sectionShipPolicyCheck':
          sectionData = sectionShipPolicyCheckAdapter(value)
          break
      }

      if (value && value.sectionId) {
        sectionData.sectionId = { id: value.sectionId }
      }

      return {
        sort,
        id,
        name,
        code,
        type,
        value: sectionData,
      }
    })
}

/** нормализация данных из массива в объект для СТРОКОВЫХ значения */
export const propertyValuesNormalizer = (
  arr: Array<PropertyValue | PropertyEnumWithDescription>
): Record<string, string> => {
  const obj: any = {}
  arr &&
    arr.forEach((property) => {
      const { property: _property, id, value, description } = property || {}
      const code = _property?.code
      if (code) {
        if (!obj[code]) {
          obj[code] = value
          if (description) {
            obj[`${code}Description`] = description
          }
        } else if (Array.isArray(obj[code])) {
          obj[code].push(value)
          if (description) {
            obj[`${code}Description`].push(description)
          }
        } else {
          obj[code] = [value, obj[code]]
          if (description) {
            obj[`${code}Description`] = [obj[`${code}Description`], description]
          }
        }
        obj[`id-${code}`] = id
      }
    })
  return obj
}

/** нормализация данных из массива в объект для РЕФЕРЕНСНЫХ полей */
export const propertyValuesRelationNormalizer = (
  arr: PropertyRelation[]
): NormalizedPageData => {
  const obj: any = {}
  arr &&
    arr.forEach((item: PropertyRelation) => {
      const { property } = item
      if (property && 'code' in property) {
        const val =
          property.type && property.type === PropertyType.File
            ? item.targetFile
            : item.targetElement || item.targetSection
        if (val) {
          if (obj[property.code]) {
            obj[property.code].push(val)
          } else {
            obj[property.code] = [val]
          }
        }
      }
    })
  return obj
}

/** адаптер для хлебных крошек */
export const parentNormalizer = (page: any) => {
  // - данные по полученной странице
  const {
    id,
    code,
    name,
    fullPath,
    sort,
    updatedAt,
    parent,
    propertyValuesRelation = [],
    propertyValuesEnum = [],
    propertyValues = [],
  } = page
  const {
    isShowHeader = 'Нет',
    uniquePages = null,
    showButtonBuyOnline = 'Нет',
    type = '',
    card = [],
    menuItemShield = '',
    menuItemShieldStyle = '',
    locationRegion = [],
    locationCity = [],
    hideInDropdownMenu,
  } = {
    ...propertyValuesRelationNormalizer(propertyValuesRelation),
    ...propertyValuesNormalizer(propertyValuesEnum),
    ...propertyValuesNormalizer(propertyValues),
  }

  const parentId = parent?.id
  const child: string[] = []
  return {
    id,
    name,
    code,
    sort,
    fullPath,
    isShowHeader: isShowHeader === 'Да',
    hideInDropdownMenu: hideInDropdownMenu === 'Да',
    uniquePages,
    type,
    card,
    updatedAt,
    showButtonBuyOnline: showButtonBuyOnline === 'Да',
    parent: parentId,
    child,
    menuItemShield,
    menuItemShieldStyle,
    locationRegion,
    locationCity,
  }
}

export const redirectPropertyValuesNormalizer = (
  arr: ReadonlyArray<PropertyValue | PropertyEnum>
) => {
  const obj: any = {}
  arr &&
    arr.forEach((property) => {
      const { property: _property, value } = property || {}
      const code = _property?.code
      if (code) {
        if (!obj[code]) {
          obj[code] = value
        } else if (Array.isArray(obj[code])) {
          obj[code].push(value)
        } else {
          obj[code] = [value, obj[code]]
        }
      }
    })
  return obj
}

export const sitePagesNormalizer = (
  elements: ReadonlyArray<AdaptedPageItem>
) => {
  const pages = elements.map((page) => parentNormalizer(page))

  pages.forEach((page) => {
    if (page.parent) {
      const parents = pages.filter((_page) => _page.id === page.parent)
      if (parents.length) {
        parents.forEach((_parent) => {
          if (_parent.code === 'index' && !_parent.child.includes(page.id)) {
            _parent.child.push(page.id)
          }
        })
      }
    }
  })
  return pages
}
