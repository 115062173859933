import { propertyValuesNormalizer } from '../pageAdapter'
import { Element } from '../../composition-functions'

export const buttonsAdapter = (buttonsAction: Element[]) => {
  return (
    buttonsAction &&
    buttonsAction.map((button: any) => {
      const { id, name, propertyValues, propertyValuesEnum } = button
      const properties = propertyValuesNormalizer([
        ...propertyValues,
        ...propertyValuesEnum,
      ])
      return {
        id,
        name,
        ...properties,
        arrow: properties.arrow === 'Да',
        padding: properties.padding ? properties.padding === 'Да' : true,
      }
    })
  )
}
