import {sectionDataAdapter} from "./sectionDataAdapter";
import {propertyValuesNormalizer, propertyValuesRelationNormalizer} from "../pageAdapter";

const serviceAdapter = (service) => {
  const {
    title,
    description,
    radius,
    bgColor,
    link,
  } = propertyValuesNormalizer(service.propertyValues);
  const {
    textPositionVertical,
    textPositionHorizontal,
    cardType,
    smallCardsTextPosition,
    isRound,
  } = propertyValuesNormalizer(service.propertyValuesEnum)
  const { image } = propertyValuesRelationNormalizer(service.propertyValuesRelation)
  return {
    title,
    description,
    radius,
    bgColor,
    link,
    image: image && image.map((img) => img.url),
    textPositionVertical,
    textPositionHorizontal,
    cardType: cardType ? Number(cardType) : cardType,
    isRound: isRound === 'Да',
    smallCardsTextPosition,
  }
}

const serviceListAdapter = (serviceList: any) => {
  const { sizes } = propertyValuesNormalizer(serviceList.propertyValues)
  const { isSlider } = propertyValuesNormalizer(serviceList.propertyValuesEnum)
  const { serviceList: cards = [] } = propertyValuesRelationNormalizer(serviceList.propertyValuesRelation)
  return {
    isSlider: isSlider === 'Да',
    sizes: typeof sizes === 'string' ? sizes.split(',').map(Number) : [],
    serviceList: cards.map(serviceAdapter),
  }
}

export const sectionServicesAdapter = (data: any) => {
  const { serviceLists= [] } = data
  return {
    sectionData: sectionDataAdapter(data),
    serviceLists: serviceLists.map(serviceListAdapter),
  }
}
