// @ts-nocheck
import { buttonsAdapter } from './buttonsAdapter'
import { sectionDataAdapter } from './sectionDataAdapter'

export const sectionImageTextCardAdapter = (data: any) => {
  const {
    title,
    text,
    textBackground,
    imageBackground,
    textOrder,
    textImageFallbackAlignment,
    buttonActions,
    textImageFallback,
    imageHeight,
    imageLink,
    imageHeightMobile,
    imageHeightTablet,
    image: [{ url: imageUrl } = {}] = [],
    iconImageFallback: [{ url: iconImageUrl } = {}] = [],
  } = data
  return {
    sectionData: sectionDataAdapter(data),
    title,
    text,
    textBackground,
    imageBackground,
    textImageFallback,
    imageLink,
    image: imageUrl,
    imageHeight: imageHeight ? Number(imageHeight) : 652,
    imageHeightMobile: imageHeightMobile ? Number(imageHeightMobile) : 180,
    imageHeightTablet: imageHeightTablet ? Number(imageHeightTablet) : 230,
    iconImageFallback: iconImageUrl,
    textOrder: Number(textOrder) || 1,
    textImageFallbackAlignment,
    buttonActions: buttonsAdapter(buttonActions),
  }
}
