// @ts-nocheck
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionVideoAdapter = (data: any) => {
  const { urlVideo, name, iframeVideo, hidePreview } = data

  return {
    sectionData: sectionDataAdapter(data),
    urlVideo,
    iframeVideo,
    hidePreview: hidePreview === 'Да',
    name,
  }
}
