// @ts-nocheck
export const sectionListTablesAdapter = (data: any) => {
  const {
    sectionMainTitle,
    infoBlockText,
    buttonText,
    buttonUrl,
  } = data
  return {
    sectionMainTitle,
    infoBlockText,
    buttonText,
    buttonUrl,
  }
}
