// @ts-nocheck
import {
  propertyValuesNormalizer,
  propertyValuesRelationNormalizer,
} from '../pageAdapter'
import { HOVER_COLORS } from '../../../contracts/Cards'
import { buttonsAdapter } from "./buttonsAdapter";
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionCardImageGridAdapter = (data: any) => {
  const {
    list = [],
    col,
    sizesMobile,
  } = data
  const newCol = col.split(',').map((itemCol) => JSON.parse(itemCol))
  const cardSizesSm = sizesMobile ? sizesMobile.split(',').map((num) => JSON.parse(num)) : []
  const value = list.map((item, index) => {
    const { propertyValuesRelation, propertyValues, propertyValuesEnum } = item
    const mappedItem = {
      ...propertyValuesNormalizer(propertyValues || []),
      ...propertyValuesNormalizer(propertyValuesEnum || []),
      ...propertyValuesRelationNormalizer(propertyValuesRelation || []),
    }
    const {
      title,
      description,
      url,
      textLink,
      alt,
      image: [{ url: image = '' } = {}] = [],
      imageRectangleFirst: [{ url: imageRectangleFirst = '' } = {}] = [],
      imageRectangleSecond: [{ url: imageRectangleSecond = '' } = {}] = [],
      hasBorder,
      hasCoverBg,
      hover,
      actionButtons,
      actionButtonsAlignment,
    } = mappedItem

    return {
      col: newCol[index],
      sm: cardSizesSm[index],
      title,
      description,
      url,
      textLink,
      image: {
        url: image,
        alt,
      },
      imageRectangleFirst: {
        url: imageRectangleFirst,
        alt,
      },
      imageRectangleSecond: {
        url: imageRectangleSecond,
        alt,
      },
      alt,
      hasBorder: hasBorder === 'Да',
      hasCoverBg: hasCoverBg === 'Да',
      actionButtons: buttonsAdapter(actionButtons),
      actionButtonsAlignment,
      hover: HOVER_COLORS[hover],
    }
  })
  const setCol = (item: any, col: number, md: number, sm: number) => {
    item.forEach((card: any) => {
      card.col = card.col || col
      card.md = card.col ? (8 * card.col) / 12 : md
      card.sm = card.sm || sm
    })
  }
  const cardsDataLocal = value
  const cardsDataLength = cardsDataLocal.length
  const hasSmValue = cardsDataLocal[cardsDataLocal.length - 1].sm
  if (cardsDataLength > 3) {
    setCol(cardsDataLocal, 4, 4, 1)
  } else {
    setCol(cardsDataLocal, 12 / cardsDataLength, 12 / cardsDataLength, 1)
  }
  if (!hasSmValue && cardsDataLength % 2 !== 0) {
    cardsDataLocal[cardsDataLength - 1].sm = 2
  }

  return {
    list: value,
    sectionData: sectionDataAdapter(data),
  }
}
