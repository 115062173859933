import { sectionDataAdapter } from "./sectionDataAdapter";
import { buttonsAdapter } from "./buttonsAdapter";

export function sectionPulseGridAdapter(data: any) {
  const { cardTitle1, cardTitle2, cardTitle3, cardDescription1, cardDescription2, cardDescription3 } = data
  return {
    sectionData: sectionDataAdapter(data),
    actionButtons: buttonsAdapter(data.actionButtons),
    cardTitle1,
    cardTitle2,
    cardTitle3,
    cardDescription1,
    cardDescription2,
    cardDescription3,
  }
}
