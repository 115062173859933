import { sectionDataAdapter } from "./sectionDataAdapter";
import { propertyValuesNormalizer } from "../pageAdapter";

export function sectionTabsAdapter(data: any) {
  return {
    sectionData: sectionDataAdapter(data),
    backgroundColor: data.backgroundColor,
    tabsBackgroundColor: data.tabsBackgroundColor,
    tabsTextColor: data.tabsTextColor,
    tabsBackgroundColorActive: data.tabsBackgroundColorActive,
    tabsTextColorActive: data.tabsTextColorActive,
    tabsBorderRadius: data.tabsBorderRadius,
    list: data.tabs.map((tab) => {
      const { id, title, content } = propertyValuesNormalizer(tab.propertyValues)
      return {
        value: tab.id,
        tabTitle: title,
        id,
        content,
      }
    })
  }
}
