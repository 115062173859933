// @ts-nocheck
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionCheckDocumentsAdapter = (data: any) => {
  const {
    privacyTitle,
    privacyLinkUrl,
    privacyLinkLabel,
  } = data
  return {
    sectionData: sectionDataAdapter(data),
    privacyTitle,
    privacyLinkUrl,
    privacyLinkLabel,
  }
}
