import {propertyValuesNormalizer, propertyValuesRelationNormalizer} from "../pageAdapter";
import {buttonsAdapter} from "./buttonsAdapter";
import {chipsAdapter} from "./chipsAdapter";

const MOBILE_JUSTIFY_CONTENT = {
  start: 'flex-start',
  space: 'space-between',
  end: 'flex-end',
}

const CHIPS_POSITIONS = {
  'Под заголовком': 'after-title',
  'Над описанием': 'before-description',
  'Под описанием': 'after-description',
}

const alternateSlideAdapter = (slide) => {
  const { title, description } = propertyValuesNormalizer(slide.propertyValues)
  const {
    actionButtons,
    chips= [],
    image: [{ url: imageUrl } = {}] = [],
    mobileImage: [{ url: mobileImageUrl } = {}] = [],
  } = propertyValuesRelationNormalizer(slide.propertyValuesRelation)
  const {
    titleAlignment,
    chipsAlignment,
    descriptionAlignment,
    chipsHorizontalAlignment,
    mobileTitleAlignment,
    mobileChipsHorizontalAlignment,
    mobileDescriptionAlignment,
    mobileJustifyContent,
  } = propertyValuesNormalizer(slide.propertyValuesEnum)
  return {
    title,
    description,
    actionButtons: buttonsAdapter(actionButtons),
    titleAlignment,
    descriptionAlignment,
    chipsHorizontalAlignment,
    chipsAlignment: CHIPS_POSITIONS[chipsAlignment],
    chips: chipsAdapter(chips),
    mobileTitleAlignment,
    mobileChipsHorizontalAlignment,
    mobileDescriptionAlignment,
    mobileJustifyContent: MOBILE_JUSTIFY_CONTENT[mobileJustifyContent],
    image: imageUrl,
    mobileImage: mobileImageUrl,
  }
}

export const sectionAlternateSliderAdapter = (data: any) => {
  const { slides } = data
  return {
    slides: slides.map(alternateSlideAdapter)
  }
}
