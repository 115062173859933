import { sectionDataAdapter } from "./sectionDataAdapter";
import { buttonsAdapter } from "./buttonsAdapter";

export function sectionFormFeedbackNewAdapter(data: any) {
  const { bannerImage: [{ url: bannerImage = ''} = {}] = [], } = data
  return {
    reject: {
      title: data.rejectTitle || '',
      description: data.rejectDescription || '',
    },
    success: {
      description: data.successDescription || '',
      personalButtonLink: data.successPersonalButtonLink || '',
      personalButtonText: data.successPersonalButtonText || '',
      mainButtonLink: data.successMainButtonLink || '',
      mainButtonText: data.successMainButtonText || '',
    },
    banner: {
      bannerTitle: data.bannerTitle || '',
      bannerDescription: data.bannerDescription || '',
      bannerLink: data.bannerLink || '',
      bannerImage,
    },
    actionButtons: buttonsAdapter(data.actionButtons),
    sectionData: sectionDataAdapter(data)
  }
}
