// @ts-nocheck
import { propertyValuesNormalizer } from '../pageAdapter'
import { Element } from '../../composition-functions'
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionCardsNumberAdapter = (data: any) => {
  const { cardList } = data
  const list = cardList.map((item: Element) => {
    const { title, description, url } = propertyValuesNormalizer(item.propertyValues)
    const { hideNumber } = propertyValuesNormalizer(item.propertyValuesEnum)
    return {
      title,
      description,
      url,
      hideNumber: hideNumber === 'Да'
    }
  })
  return {
    sectionData: sectionDataAdapter(data),
    list,
  }
}
