import { sectionDataAdapter } from "./sectionDataAdapter";
import { buttonsAdapter } from "./buttonsAdapter";

export function sectionImageBackgroundAdapter(data: any) {
  const { title, description, background, image: [{ url }] = [{}] } = data
  return {
    sectionData: sectionDataAdapter(data),
    actionButtons: buttonsAdapter(data.actionButtons),
    title,
    description,
    background,
    image: url,
  }
}
