import { propertyValuesNormalizer } from '../pageAdapter'
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionChildrenSportAdapter = (data: any) => {
  const {
    templateLink,
    bodyAppealOptions,
    headerAppealOptions,
    elementsInsuranceCoverBiznes,
    elementsInsuranceCoverIndividual,
    individualCoverDocumentLink,
    businessCoverDocumentLink,
    hideDefault,
    recipients,
    privacyTitle,
    privacyLinkUrl,
    privacyLinkLabel,
  } = data

  const insuranceCoverBusiness = elementsInsuranceCoverBiznes.map(
    (insuranceCover) => {
      return propertyValuesNormalizer(insuranceCover.propertyValues)
    }
  )
  const insuranceCoverIndividual = elementsInsuranceCoverIndividual.map(
    (insuranceCover) => {
      return propertyValuesNormalizer(insuranceCover.propertyValues)
    }
  )

  return {
    sectionData: sectionDataAdapter(data),
    privacyTitle,
    privacyLinkUrl,
    privacyLinkLabel,
    info: {
      individualCoverDocumentLink,
      businessCoverDocumentLink,
      insuranceCoverBusiness,
      insuranceCoverIndividual,
      templateLink,
      hideDefault: hideDefault === 'Да',
      bodyAppealOptions,
      headerAppealOptions,
      recipients,
    },
  }
}
