import { propertyValuesNormalizer, propertyValuesRelationNormalizer } from "../pageAdapter";

export function insuranceItemAdapter(data: any) {
  const { title, description, sum } = propertyValuesNormalizer(data.propertyValues)
  const { icon: [{ url } = {}] = [] } = propertyValuesRelationNormalizer(data.propertyValuesRelation)
  const { checked } = propertyValuesNormalizer(data.propertyValuesEnum)
  return {
    code: data.id,
    title,
    description,
    sum,
    icon: url,
    checked: checked === 'Да' || false,
  }
}
