// @ts-nocheck
import {
  propertyValuesNormalizer,
  propertyValuesRelationNormalizer,
} from '../pageAdapter'
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionCardNewsAdapter = (data: any) => {
  const {
    list: [{ propertyValuesRelation = [] } = {}] = [],
    loadLastNews,
  } = data

  const newList = propertyValuesRelationNormalizer(propertyValuesRelation)
  const news = newList.list
    ? newList.list
        .slice(0, 3)
        .map((item) => {
          const { activeFrom: date, propertyValues } = item
          const { title = '' } = propertyValuesNormalizer(propertyValues)
          return {
            date,
            title,
            url: `/${item.code}`,
          }
        })
        .sort(({ date: dateA }, { date: dateB }) => {
          return new Date(dateA) > new Date(dateB) ? -1 : 1
        })
    : null

  return {
    sectionData: sectionDataAdapter({ ...data, linkUrl: data.url, linkText: data.value }),
    news,
    loadLastNews: !!loadLastNews,
  }
}
