// @ts-nocheck
export const sectionSearchVacanciesAdapter = (data: any) => {
  const {
    title,
    sideTitle,
    sectionId,
    textButton,
    emptyVacanciesTitle,
    emptyVacanciesText,
    vacanciesTitle,
    speciality: [{ id, name } = {}] = [],
  } = data

  const sectionDataLocal = {
    sideTitle,
    sectionId,
    textButton,
    emptyVacanciesTitle,
    emptyVacanciesText,
    titleFilters: title,
    vacanciesTitle,
    speciality: {
      id,
      name,
    },
  }

  const newData = {
    sectionDataLocal,
  }

  return {
    ...newData,
  }
}
