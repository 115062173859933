import { sectionDataAdapter } from "./sectionDataAdapter";
import { buttonsAdapter } from "./buttonsAdapter";
import { propertyValuesNormalizer, propertyValuesRelationNormalizer } from "../pageAdapter";

export function sectionCallToActionAdapter(data: any) {
  const { title = null } = data
  const { actionButtons = [], image = [] } = data
  return {
    title,
    sectionData: sectionDataAdapter(data),
    buttons: buttonsAdapter(actionButtons),
    image: image.length ? image.map((img) => img.url)[0] : null
  }
}
