// @ts-nocheck
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionLeadDetailAdapter = (data: any) => {
  const { title, alt, textData, image } = data
  const pictureData = { title, image, alt }
  if (image) {
    const [imN] = image
    pictureData.image = imN
  }

  return {
    textData,
    pictureData,
    sectionData: sectionDataAdapter({
      ...data,
      linkText: 'Вернуться в раздел «Руководство»',
      linkUrl: '#',
    }),
  }
}
