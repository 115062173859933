// @ts-nocheck
import {
  propertyValuesNormalizer,
  propertyValuesRelationNormalizer,
} from '../pageAdapter'
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionBlockTagRowAdapter = (data: any) => {
  const { list: [{ propertyValuesRelation = [] } = {}] = [] } = data
  const newList = propertyValuesRelationNormalizer(propertyValuesRelation)
  const tagsData = newList.list.map((item) => {
    const mappedItem = propertyValuesNormalizer(item.propertyValues)
    const { linkText, linkUrl = '' } = mappedItem
    return {
      linkText,
      linkUrl,
    }
  })
  return {
    tagsData,
    sectionData: sectionDataAdapter(data),
  }
}
