// @ts-nocheck
import { propertyValuesRelationNormalizer } from '../pageAdapter'
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionListAlphabetAdapter = (data: any) => {
  const {
    list: [
      { propertyValuesRelation: listPropertyValuesRelation = [] } = {},
    ] = [],
  } = data
  const valuesRelation =
    propertyValuesRelationNormalizer(listPropertyValuesRelation).list || []
  const list = valuesRelation.map((item) => {
    return {
      linkText: item.name,
      linkUrl: item.code,
      append: true,
    }
  })
  return {
    list,
    sectionData: sectionDataAdapter(data),
  }
}
