import {propertyValuesNormalizer} from "../pageAdapter";

export const chipsAdapter = (data: any) => {
  return data.map((chip) => {
    const { title, bgColor, radius } = propertyValuesNormalizer(chip.propertyValues)
    return {
      id: chip.id,
      title,
      bgColor,
      radius,
    }
  })
}
