// @ts-nocheck
import {
  propertyValuesRelationNormalizer,
  propertyValuesNormalizer,
} from '../pageAdapter'
import { Element } from '@/apollo/composition-functions'
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionListAdapter = (data: any, extended?: any) => {
  const { list = [] } = data

  let newList = []
  let newText = ''
  if (list.length === 1) {
    const [item] = list
    const _list = listNormalize(item)
    const { text } = listNormalizeW(item)
    newText = text
    newList = _list.map((item: Element) => listItemNormalize(item))
  } else {
    newList = list.map((item: Element) => {
      const { list, title, text } = { ...listNormalizeW(item) }
      return {
        title,
        text,
        list: list.map((item: Element) => listItemNormalize(item)),
      }
    })
  }
  return {
    isList: !!newList && !!newList[0] && !!newList[0].list,
    list: newList,
    text: newText,
    isExtended: extended,
    sectionData: sectionDataAdapter(data),
  }
}

export const normalizeProps = (
  propertyValues: any,
  propertyValuesRelation: any,
  propertyValuesEnum: any
) => {
  return {
    ...propertyValuesNormalizer(propertyValues),
    ...propertyValuesNormalizer(propertyValuesEnum),
    ...propertyValuesRelationNormalizer(propertyValuesRelation),
  }
}

export const listNormalizeW = (listItem: Element) => {
  const { propertyValues, propertyValuesRelation, propertyValuesEnum } =
    listItem
  const {
    title = '',
    text = '',
    list = [],
  } = {
    ...normalizeProps(
      propertyValues,
      propertyValuesRelation,
      propertyValuesEnum
    ),
  }
  return { title, list, text }
}

export const listNormalize = (listItem: Element) => {
  const { propertyValues, propertyValuesRelation, propertyValuesEnum } =
    listItem
  const { list = [] } = {
    ...normalizeProps(
      propertyValues,
      propertyValuesRelation,
      propertyValuesEnum
    ),
  }
  return list
}

export const listItemNormalize = (item: Element) => {
  const { propertyValues, propertyValuesEnum, propertyValuesRelation } = item
  const value = normalizeProps(
    propertyValues,
    propertyValuesRelation,
    propertyValuesEnum
  )
  return value
}
