import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionCheckAgentsAdapter = (data: any) => {
  const { isNew } = data

  return {
    sectionData: {
      ...sectionDataAdapter(data),
      isNew: isNew === 'Да'
    },
    list: []
  }
}
