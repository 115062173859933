import { sectionDataAdapter } from "./sectionDataAdapter";
import { buttonsAdapter } from "./buttonsAdapter";
import { propertyValuesNormalizer, propertyValuesRelationNormalizer } from "../pageAdapter";
import { insuranceItemAdapter } from "./insuranceItemAdapter";
import { tagsAdapter } from "./tagsAdapter";

export function sectionPulseFormAdapter(data: any) {
  const { insuranceList = [], description, cardInsuranceRateList = []} = data
  return {
    sectionData: sectionDataAdapter(data),
    actionButtons: buttonsAdapter(data.actionButtons),
    description,
    cardInsuranceRateList: cardInsuranceRateList.map((card) => {
      const { title, sum, detail, text } = propertyValuesNormalizer(card.propertyValues)
      const { insuranceList = [], actionButtons = [], tags = [] } = propertyValuesRelationNormalizer(card.propertyValuesRelation)
      return {
        title,
        sum,
        detail,
        text,
        tags: tagsAdapter(tags),
        actionButtons: buttonsAdapter(actionButtons),
        insuranceList: insuranceList.map((insurance) => insuranceItemAdapter(insurance))
      }
    }),
    insuranceList: insuranceList.map((insurance) => insuranceItemAdapter(insurance))
  }
}
