// @ts-nocheck
import { buttonsAdapter } from './buttonsAdapter'

export const sectionDataAdapter = (data: any) => {
  const {
    formTitleSecondPart,
    formTitleSecondPartEng,
    policyAccept,
    sectionMainTitle,
    sectionMainTitleEng,
    sectionSideTitle,
    sectionSideTitleEng,
    sectionId,
    sectionDescription,
    sectionDate,
    sectionButtonsAlignment,
    sectionButtons,
    sectionFormDescriptionSecondPart,
    sectionFormDescriptionSecondPartEng,
    switchLangLink,
    switchLangLinkEng,
    switchLangLinkText,
    switchLangLinkTextEng,
    langType,
    linkText,
    linkUrl,
    sectionBackground,
    offsetDesktop = null,
    offsetMobile = null,
  } = data

  return {
    formTitleSecondPart,
    formTitleSecondPartEng,
    policyAccept,
    sectionMainTitle,
    sectionMainTitleEng,
    sectionSideTitle,
    sectionSideTitleEng,
    sectionId,
    sectionDescription,
    sectionDate,
    sectionButtonsAlignment,
    sectionBackground,
    sectionButtons: buttonsAdapter(sectionButtons),
    sectionLink: linkText
      ? {
          value: linkText,
          link: linkUrl || '#',
          arrowType: 'arrow-right',
        }
      : null,
    sectionFormDescriptionSecondPart,
    sectionFormDescriptionSecondPartEng,
    switchLangLink,
    switchLangLinkEng,
    switchLangLinkText,
    switchLangLinkTextEng,
    offset: {
      desktop: offsetDesktop
        ? /^\d+,\d+$/.test(offsetDesktop) &&
          offsetDesktop.split(',').map(Number)
        : null,
      mobile: offsetMobile
        ? /^\d+,\d+$/.test(offsetMobile) && offsetMobile.split(',').map(Number)
        : null,
    },
    langType,
  }
}
