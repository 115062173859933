export const FAQMicrodata = (faqComponents) => {
  if (!faqComponents.length) return ''
  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqComponents.flatMap((faqComponent) =>
      faqComponent.value.list.flatMap((listItem) => {
        if (listItem.list) {
          return listItem.list.map((question) => ({
            '@type': 'Question',
            name: question.title,
            acceptedAnswer: {
              '@type': 'Answer',
              text: question.description,
            },
          }))
        }
        return {
          '@type': 'Question',
          name: listItem.title,
          acceptedAnswer: {
            '@type': 'Answer',
            text: listItem.description,
          },
        }
      })
    ),
  })
}

export const siteHead = (params = {}) => {
  const { color = '#b70037', title = null, script = [], noscript = [] } = params
  const microformat = {
    hid: 'microformat',
    innerHTML: `
    {
      "@context": "http://schema.org",
      "@type": "Organization",
      "url": "https://www.rgs.ru/",
      "name": "Росгосстрах",
      "legalName": "ПАО СК «Росгосстрах»",
      "logo": "https://www.rgs.ru/upload/medialibrary/998/logo_1200_1.jpg",
      "contactPoint": [{
        "@type": "ContactPoint", "telephone": "+78002000900",
        "contactType": "customer service"
      }],
      "sameAs": [
        "https://www.facebook.com/RGS.ru",
        "https://vk.com/rgstrakh",
        "https://www.instagram.com/rgs_ru/",
        "https://www.youtube.com/user/Rosgosstrakh/"
      ]
    }
  `,
    type: 'application/ld+json',
    charset: 'utf-8',
  }
  return {
    htmlAttrs: {
      lang: 'ru',
      'xmlns:og': 'http://ogp.me/ns#',
    },
    title: title || process.env.npm_package_name || '',
    link: [
      {
        rel: 'icon',
        sizes: '16x16',
        type: 'image/png',
        href: '/favicon-16x16.png',
      },
      {
        rel: 'icon',
        sizes: '32x32',
        type: 'image/png',
        href: '/favicon-32x32.png',
      },
      {
        rel: 'icon',
        type: 'image/ico',
        href: '/favicon.ico',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: '/apple-touch-icon-180x180.png',
      },
      {
        rel: 'manifest',
        href: '/manifest.json',
      },
    ],
    meta: [
      {
        charset: 'utf-8',
      },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1, viewport-fit=cover',
      },
      {
        name: 'format-detection',
        content: 'telephone=no',
      },
      {
        name: 'msapplication-TileColor',
        content: color,
      },
      {
        name: 'theme-color',
        content: color,
      },
      {
        property: 'og:locale',
        content: 'ru_RU',
      },
      {
        property: 'og:type',
        content: 'website',
      },
      {
        name: 'twitter:card',
        content: 'summary_large_image',
      },
      {
        name: 'yandex-verification',
        content: 'daf0840e76eb022b',
      },
    ],
    // эта штука нужна, чтобы можно было вставлять скрипты inline
    __dangerouslyDisableSanitizers: ['script'],
    script: [microformat, ...script],
    noscript,
  }
}
