// @ts-nocheck
import {
  propertyValuesRelationNormalizer,
  propertyValuesNormalizer,
} from '../pageAdapter'
import { questionListItemNormalize } from './faqAdapter'
import { HOVER_COLORS } from "../../../contracts/Cards";
import { buttonsAdapter } from "./buttonsAdapter";
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionCardImageIconAdapter = (data: any) => {
  const {
    list: [{ propertyValuesRelation = [], propertyValues = [] } = {}] = [],
    banner,
  } = data

  const value = propertyValuesRelationNormalizer(propertyValuesRelation)
  const propertyValuesItem = propertyValuesNormalizer(propertyValues)
  const sizes = propertyValuesItem.sizes?.split(',')
  const list = value.list?.map((item, index) => {
    const mappedItem = propertyValuesNormalizer(item.propertyValues)
    const mappedItemValuesRelation = propertyValuesRelationNormalizer(
      item.propertyValuesRelation
    )
    const { hover } = propertyValuesNormalizer(item.propertyValuesEnum)
    const { image: [{ url: image = '' } = {}] = [], actionButtons } = mappedItemValuesRelation
    return {
      ...mappedItem,
      hover: HOVER_COLORS[hover],
      actionButtons: buttonsAdapter(actionButtons),
      col: (sizes && Number.parseInt(sizes[index])) || 'auto',
      image,
    }
  })

  let bannerData
  if (banner) {
    const [item = {}] = banner
    const {
      title = '',
      linkText = '',
      linkUrl = '',
      imageDesktop: [{ url: imageDesktop = [] } = {}] = [],
      imageMobile: [{ url: imageMobile = [] } = {}] = [],
      alt = '',
      htmlText,
      hover,
      actionButtons,
    } = questionListItemNormalize(item)
    bannerData = {
      title,
      linkText,
      url: linkUrl,
      imageDesktop,
      imageMobile,
      alt,
      htmlText,
      actionButtons: buttonsAdapter(actionButtons),
      hover: HOVER_COLORS[hover],
    }
  }
  return {
    bannerData,
    sectionData: sectionDataAdapter(data),
    cardsData: list,
  }
}
