// @ts-nocheck
import { questionListItemNormalize } from './faqAdapter'
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionTextAdapter = (data: any) => {
  const { date = '', text, fullwidth, title = '' } = data
  const [textEl = {}] = text || [{}]
  const { text: textData = '', showFullTable } = questionListItemNormalize(textEl)

  return {
    sectionData: sectionDataAdapter({
      ...data,
      sectionDate: date,
      sectionMainTitle: title,
    }),
    date,
    textData,
    showFullTable,
    fullwidth: fullwidth === 'Да',
  }
}
