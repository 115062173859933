// @ts-nocheck
import { listVacanciesAdapter } from './listVacanciesAdapter'
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionVacanciesListAdapter = (data: any) => {
  const { vacanciesList } = data

  const listVacancies = listVacanciesAdapter(vacanciesList)

  return {
    sectionCardNewsData: sectionDataAdapter({
      ...data,
      sectionMainTitle: data.title,
      sectionSideTitle: data.tag,
      linkText: data.textLink,
      linkUrl: data.urlLink,
    }),
    listVacancies
  }
}
