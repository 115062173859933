// @ts-nocheck
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionCheckBlanksAdapter = (data: any) => {
  const { 'id-idRecipients': idRecipients } = data
  return {
    idRecipients,
    sectionData: sectionDataAdapter({ ...data, sectionMainTitle: data.title }),
  }
}
