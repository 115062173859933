// @ts-nocheck
import {
  propertyValuesRelationNormalizer,
  propertyValuesNormalizer,
} from '../pageAdapter'
import { buttonsAdapter } from './'

export type HeroSlide = {
  title: string
  tabTitle: string
  isDarkTheme: boolean
  description: string
  image: {
    src: string
    alt: string
  }
  imageRectangle: {
    src: string
    alt: string
  }
  basicLink: {
    url: string
    text: string
  }
  secondLink: {
    url: string
    text: string
  }
  buttonsAction?: any[]
  menuItemShield?: string
  menuItemShieldStyle?: string
}

export type HeroSliderData = {
  list: HeroSlide[]
}

export const sectionHeroSliderAdapter = (data: any): HeroSliderData => {
 
  const { list: [{ propertyValuesRelation = [] } = {}] = [] } = data
  const value = propertyValuesRelationNormalizer(propertyValuesRelation)
  const list = value.list.map((item) => {
    const mappedItem = propertyValuesNormalizer(item.propertyValues)
    const mappedEnumItem = propertyValuesNormalizer(item.propertyValuesEnum)
    const mappedItemValuesRelation = propertyValuesRelationNormalizer(
      item.propertyValuesRelation
    )
      
    const {
      image: [{ url: image = '' } = {}] = [],
      imageRectangle: [{ url: imageRectangle = '' } = {}] = [],
      buttonsAction,
    } = mappedItemValuesRelation
    return {
      title: mappedItem.title,
      tabTitle: mappedItem.tabTitle,
      isDarkTheme: mappedEnumItem.isDarkTheme === 'Да',
      description: mappedItem.description,
      image: {
        src: image,
        alt: mappedItem.alt,
      },
      imageRectangle: {
        src: imageRectangle,
        alt: mappedItem.alt,
      },
      basicLink: {
        url: mappedItem.basicLinkUrl,
        text: mappedItem.basicLinkText,
      },
      secondLink: {
        url: mappedItem.secondLinkUrl,
        text: mappedItem.secondLinkText,
      },
      buttonsAction: buttonsAdapter(buttonsAction),
      menuItemShield: mappedItem.menuItemShield,
      menuItemShieldStyle: mappedEnumItem.menuItemShieldStyle,
    }
  })

  return {
    list,
  }
}
