import { sectionDataAdapter } from './sectionDataAdapter'

export const sectionShipPolicyCheckAdapter = (data: any) => {
  const {
    sectionMainTitle,
    sectionTabName,
    sectionTabImo,
    sectionBtnTitle,
    sectionPlaceholderImo,
    sectionPlaceholderName,
    notFoundText,
  } = data

  return {
    sectionMainTitle,
    sectionTabName,
    sectionTabImo,
    sectionBtnTitle,
    sectionPlaceholderImo,
    sectionPlaceholderName,
    notFoundText,
  }
}
