import { sectionDataAdapter } from "./sectionDataAdapter";

export function sectionAgentsFeedbackAdapter(data) {
  const {
    insuranceType = [],
    formTitle,
    successMessage,
    isDarkTheme,
    privacyTitle,
    privacyLinkUrl,
    privacyLinkLabel,
  } = data
  return {
    sectionData: sectionDataAdapter(data),
    formTitle,
    privacyTitle,
    privacyLinkUrl,
    privacyLinkLabel,
    successMessage,
    isDarkTheme: isDarkTheme === 'Да',
    insuranceTypes: insuranceType.map((insurance) => ({
      code: insurance.code,
      name: insurance.name,
    })),
  }
}
