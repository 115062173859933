// @ts-nocheck
import { IFormContactBlock } from '../../../contracts/IFormContactBlock'
import { LinkType } from '../../../contracts/LinkType'
import {
  propertyValuesRelationNormalizer,
  propertyValuesNormalizer,
} from '../pageAdapter'

export const listBlockAdapter = (data: any): IFormContactBlock => {
  const titleObject: { title: string } = propertyValuesNormalizer(
    data.propertyValues
  )

  const listObject: { list: [] } = propertyValuesRelationNormalizer(
    data.propertyValuesRelation
  )

  const mappedList = listObject.list.map((x: any) => {
    const adapted = propertyValuesNormalizer(x.propertyValues)
    const typeObject = propertyValuesNormalizer(x.propertyValuesEnum)

    return {
      ...adapted,
      ...typeObject,
    }
  })

  return {
    ...titleObject,
    list: [...mappedList],
  }
}
