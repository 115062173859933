// @ts-nocheck
import {
  propertyValuesRelationNormalizer,
  propertyValuesNormalizer,
} from '../pageAdapter'
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionImagesAdapter = (data: any) => {
  const { list: [{ propertyValuesRelation = [] } = {}] = [] } = data
  const value = propertyValuesRelationNormalizer(propertyValuesRelation)
  const list = value?.list?.map((item) => {
    const mappedItemValues = propertyValuesNormalizer(item.propertyValues)
    const mappedItemValuesRelation = propertyValuesRelationNormalizer(
      item.propertyValuesRelation
    )
    const { image: [{ url: image = '' } = {}] = [] } = mappedItemValuesRelation

    return {
      ...mappedItemValues,
      src: image,
    }
  }) || []
  return {
    sectionData: sectionDataAdapter({
      ...data,
      linkUrl: data.link,
      linkText: data.value,
    }),
    list,
  }
}
