// @ts-nocheck
export const sectionVacanciesHeroAdapter = (data: any) => {
  const { title, buttonText, sectionId } = data

  return {
    title,
    buttonText,
    sectionId
  }
}
