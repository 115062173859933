export type DetailPages =
  | 'Поиск'
  | 'Страница новости'
  | 'Страница руководителя'
  | 'ОСАГО - регион'
  | 'КАСКО - регион'
  | 'ПОЕХАЛИ - регион'
  | 'ОСАГО - авто'
  | 'КАСКО - авто'
  | 'ОСАГО - модель авто'
  | 'КАСКО - модель авто'
  | 'ПОЕХАЛИ - страна'
  | 'Поиск вакансий со списком'
  | 'Страница вакансии'
  | 'Страница офиса'
  | 'ВЗР - Риски'
  | 'ВЗР - Виды спорта'
  | 'Клещ - регион'
  | 'НС - регион'
  | 'НС - Виды спорта'
  | 'НС - Риски'
  | 'Ипотека онлайн - регион'
  | 'Ипотека онлайн - застройщики'
  | 'Страхование дома онлайн - риски'
  | 'Страхование дома онлайн - виды спорта'
  | 'Страхование дома онлайн - регион'
  | 'Страхование квартиры онлайн - виды спорта'
  | 'Страхование квартиры онлайн - риски'
  | 'Страхование квартиры онлайн - регион'

export type ProductTypes =
  | 'osago'
  | 'letsGo'
  | 'kasko'
  | 'kleshchProtection'
  | 'accident'
  | 'ipotekaOnline'
  | 'insuranceHomeOnline'
  | 'insuranceApartmentOnline'

export type SeoBlocks =
  | 'seo-region'
  | 'seo-auto'
  | 'seo-auto-model'
  | 'seo-countries'
  | 'seo-risks'
  | 'seo-sports'
  | 'seo-developer'

export type UniqueTypePages =
  | 'Поиск вакансий со списком'
  | 'Страница вакансии'
  | 'Страница новости'
  | 'Страница руководителя'
  | 'Страница офиса'
  | 'Список новостей'
  | 'Поиск'
  | 'Карта'

export const TypePages = {
  vacancies: 'Поиск вакансий со списком',
  vacancy: 'Страница вакансии',
  search: 'Поиск',
  news: 'Страница новости',
  lead: 'Страница руководителя',
  offices: 'Страница офиса',
  mapPage: 'Карта',
  newsPage: 'Список новостей',
} as const

export const TypePagesProducts = {
  osagoRegion: 'ОСАГО - регион',
  kaskoRegion: 'КАСКО - регион',
  letsGoRegion: 'ПОЕХАЛИ - регион',
  osagoAuto: 'ОСАГО - авто',
  kaskoAuto: 'КАСКО - авто',
  osagoModelAuto: 'ОСАГО - модель авто',
  kaskoModelAuto: 'КАСКО - модель авто',
  letsGoCountry: 'ПОЕХАЛИ - страна',
  letsGoSports: 'ВЗР - Виды спорта',
  letsGoRisks: 'ВЗР - Риски',
  kleshchRegion: 'Клещ - регион',
  accidentRegion: 'НС - регион',
  accidentSports: 'НС - Виды спорта',
  accidentRisks: 'НС - Риски',
  ipotekaOnlineRegion: 'Ипотека онлайн - регион',
  ipotekaOnlineDeveloper: 'Ипотека онлайн - застройщики',
  insuranceHomeOnlineSports: 'Страхование дома онлайн - виды спорта',
  insuranceHomeOnlineRisks: 'Страхование дома онлайн - риски',
  insuranceHomeOnlineRegion: 'Страхование дома онлайн - регион',
  insuranceApartmentOnlineSports: 'Страхование квартиры онлайн - виды спорта',
  insuranceApartmentOnlineRisks: 'Страхование квартиры онлайн - риски',
  insuranceApartmentOnlineRegion: 'Страхование квартиры онлайн - регион',
  lead: 'Страница руководителя',
} as const

export const ReversedTypePagesProducts = Object.fromEntries(
  Object.entries(TypePagesProducts).map(([key, value]) => [value, key])
)

export const PopularPagesTypes: Record<string, DetailPages> = {
  search: 'Поиск',
  detailNews: 'Страница новости',
  detailLead: 'Страница руководителя',
  offices: 'Страница офиса',
  osagoAuto: 'ОСАГО - авто',
  osagoRegion: 'ОСАГО - регион',
  osagoModelAuto: 'ОСАГО - модель авто',
  letsGoRegion: 'ПОЕХАЛИ - регион',
  letsGoCountry: 'ПОЕХАЛИ - страна',
  kaskoRegion: 'КАСКО - регион',
  kaskoAuto: 'КАСКО - авто',
  kaskoModelAuto: 'КАСКО - модель авто',
  searchVacancies: 'Поиск вакансий со списком',
  pageVacancy: 'Страница вакансии',
  letsGoSports: 'ВЗР - Виды спорта',
  letsGoRisks: 'ВЗР - Риски',
  kleshchRegion: 'Клещ - регион',
  accidentRegion: 'НС - регион',
  accidentSports: 'НС - Виды спорта',
  accidentRisks: 'НС - Риски',
  ipotekaOnlineRegion: 'Ипотека онлайн - регион',
  ipotekaOnlineDeveloper: 'Ипотека онлайн - застройщики',
  insuranceHomeOnlineSports: 'Страхование дома онлайн - виды спорта',
  insuranceHomeOnlineRisks: 'Страхование дома онлайн - риски',
  insuranceHomeOnlineRegion: 'Страхование дома онлайн - регион',
  insuranceApartmentOnlineRisks: 'Страхование квартиры онлайн - риски',
  insuranceApartmentOnlineSports: 'Страхование квартиры онлайн - виды спорта',
  insuranceApartmentOnlineRegion: 'Страхование квартиры онлайн - регион',
}
export const Products: Record<string, ProductTypes> = {
  osago: 'osago',
  letsGo: 'letsGo',
  kasko: 'kasko',
  kleshch: 'kleshchProtection',
  accident: 'accident',
  ipotekaOnline: 'ipotekaOnline',
  insuranceHomeOnline: 'insuranceHomeOnline',
  insuranceApartmentOnline: 'insuranceApartmentOnline',
}

export const SeoPage: Record<string, SeoBlocks> = {
  region: 'seo-region',
  auto: 'seo-auto',
  modelAuto: 'seo-auto-model',
  country: 'seo-countries',
  risks: 'seo-risks',
  sports: 'seo-sports',
  developer: 'seo-developer',
}

export const SeoPageSitemapList = {
  region: 'seoRegion',
  auto: 'seoAuto',
  country: 'seoCountry',
  sports: 'seoSports',
  risks: 'seoRisks',
  developer: 'seoDevelopers',
  lead: 'leads',
}

export const SeoPageProperties: Record<keyof typeof SeoPage, string> = {
  'seo-region': 'Region',
  'seo-auto': 'Mark',
  'seo-auto-model': 'Model',
  'seo-countries': 'Country',
  'seo-risks': 'Risks',
  'seo-sports': 'Sports',
  'seo-developer': 'Developer',
}
