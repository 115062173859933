// @ts-nocheck
export const sectionGridAdapter = (data: any) => {
  const {
    title1,
    description1,
    title2,
    description2,
    title3,
    description3,
    title4,
    description4,
    title5,
    description5,
  } = data
  const arr = [
    {
      title: title1,
      description: description1,
    },
    {
      title: title2,
      description: description2,
    },
    {
      title: title3,
      description: description3,
    },
    {
      title: title4,
      description: description4,
    },
    {
      title: title5,
      description: description5,
    },
  ]
  return {
    gridData: arr,
  }
}
