import { sectionDataAdapter } from './sectionDataAdapter'
import {
  propertyValuesNormalizer,
  propertyValuesRelationNormalizer,
} from '../pageAdapter'

function advantageCardAdapter(advantage) {
  const { subtitle, text, background, radius } = propertyValuesNormalizer(
    advantage.propertyValues
  )
  const { image: [{ url: image = '' } = {}] = [] } =
    propertyValuesRelationNormalizer(advantage.propertyValuesRelation)

  return {
    subtitle,
    text,
    background,
    radius,
    image,
    id: advantage.id,
  }
}

export function sectionCardsAdvantagesAdapter(data) {
  return {
    sectionData: sectionDataAdapter(data),
    advantageImage: data.advantageImage
      ? data.advantageImage.map(advantageCardAdapter)[0]
      : null,
    advantages: data.advatages ? data.advatages.map(advantageCardAdapter) : [],
  }
}
