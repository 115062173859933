export const setISOFormat = (date) => {
  if (!date) {
    return null
  }
  const newDate = new Date(date)

  // разнца часового пояса сервера в милисекудах от гринвича
  const offset = newDate.getTimezoneOffset() * 60 * 1000

  // разница часового пояса МСК от гринвича в милисекундах
  const mskOffset = 3 * 60 * 60 * 1000

  const time = newDate.getTime()

  return new Date(time - offset - mskOffset).toISOString()
}
