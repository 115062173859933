import { propertyValuesNormalizer, propertyValuesRelationNormalizer } from "../pageAdapter";
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionPromocodeBanner = (data: any) => {
  const {
    addDescription,
    promocode,
    promoLinkTitle,
    promoLinkString,
  } = data
  return {
    sectionData: sectionDataAdapter(data),
    addDescription,
    promocode,
    promoLinkTitle,
    promoLinkString,
  }
}
