// @ts-nocheck
import { questionListItemNormalize } from './faqAdapter'
import { HOVER_COLORS } from "../../../contracts/Cards";
import { buttonsAdapter } from "./buttonsAdapter";
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionBlockBannerAdapter = (data: any) => {
  const { banner, textPreview } = data

  const [item = {}] = banner || []
  const {
    title = '',
    linkText = '',
    linkUrl: url = '',
    imageDesktop: [{ url: imageDesktop = '' } = {}] = [],
    imageMobile: [{ url: imageMobile = '' } = {}] = [],
    alt = '',
    htmlText,
    hover,
    actionButtons,
  } = questionListItemNormalize(item)
  const bannerData = {
    title,
    linkText,
    url,
    imageDesktop,
    imageMobile,
    alt,
    htmlText,
    actionButtons: buttonsAdapter(actionButtons),
    hover: HOVER_COLORS[hover],
  }
  return {
    bannerData: (imageDesktop || imageMobile) ? bannerData : null,
    textPreview,
    sectionData: sectionDataAdapter(data),
  }
}
