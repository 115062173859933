// @ts-nocheck
import {
  propertyValuesNormalizer,
  propertyValuesRelationNormalizer,
  sectionNormalizer
} from "../pageAdapter";
import { Element } from '../../composition-functions'

export const sectionInstructionsTestAdapter = (data: any) => {
  const {
    linkText,
    linkUrl,
    sectionMainTitle,
    sectionId,
    firstQuestion: [
      firstQuestion,
    ] = [],
  } = data
  const results = resultNormalizer(data)
  const adaptedQuestion  = firstQuestion ? [questionAdapter(firstQuestion)] : []
  return {
    sectionData: { sectionMainTitle, sectionId },
    textProgressBar: 'Ответьте на вопросы для уточнения информации',
    data: results.length ? [] : adaptedQuestion,
    firstResults: results.length ? results : null,
    link: {
      linkText,
      linkUrl,
    },
  }
}

export const answerNormalizer = (answer: Element) => {
  const { id, propertyValuesRelation, propertyValues } = answer
  const properties = propertyValuesRelationNormalizer(propertyValuesRelation)

  const { title } = propertyValuesNormalizer(propertyValues)
  const answerRes: any = { linkText: title, id }
  if ('result' in properties) {
    answerRes.results = resultNormalizer(properties)
  } else if ('question' in properties) {
    answerRes.nextQuestion = nextQuestionNormalizer(properties)
  }

  return answerRes
}

export const answerRegionNormalizer = (region: Element) => {
  const { id, propertyValuesRelation, propertyValues } = region
  const properties = propertyValuesRelationNormalizer(propertyValuesRelation)
  const { title } = propertyValuesNormalizer(propertyValues)
  const answerRes: any = {
    id,
    linkText: title,
  }
  if ('result' in properties) {
    answerRes.results = resultNormalizer(properties)
  } else if ('question' in properties) {
    answerRes.nextQuestion = nextQuestionNormalizer(properties)
  }

  return answerRes
}

export const nextQuestionNormalizer = (question: any) => {
  const { question: [{ name, id }] = [] } = question
  return { name, id }
}

export const resultNormalizer = (res: any) => {
  const {
    result: [
      {
        propertyValuesRelation = [],
        propertyValues = [],
        propertyValuesEnum = []
      }
    ] = [{}] } = res
  const {
    firstActions = null,
    info = null,
    nextActions = null,
    infoLast = null,
    form = null,
    formLast = null,
  } = propertyValuesRelationNormalizer(propertyValuesRelation)
  const {
    titleFirstActions = '',
    titleNextActions = '',
    titleForm = '',
    titleFormLast = '',
  } = propertyValuesNormalizer(propertyValues)
  const { hideSteps } = propertyValuesNormalizer(propertyValuesEnum)

  const results = []
  if (form) {
    results.push({
      name: 'SectionForm',
      title: titleForm,
      data: sectionNormalizer(form)[0].value,
    })
  }
  if (info) {
    const [{ propertyValues }] = info
    const {
      textPreview,
      textToOpen,
      title = '',
      backgroundColor = '',
      textColor = '',
    } = propertyValuesNormalizer(propertyValues)
    results.push({
      name: 'BlockWarning',
      shortBlock: true,
      data: {
        title,
        textPreview,
        textToOpen,
        backgroundColor,
        textColor,
      },
    })
  }
  if (firstActions) {
    results.push({
      name: 'BlockInstructionResultList',
      title: titleFirstActions,
      data: {
        list: firstActions.map((item) => {
          const { name, description } = propertyValuesNormalizer(
            item.propertyValues
          )
          return { title: name, description }
        }),
      },
    })
  }
  if (nextActions) {
    results.push({
      name: 'CardInstructionList',
      title: titleNextActions,
      shortBlock: true,
      data: {
        list: nextActions.map((item, i) => {
          const {
            buttonText,
            title,
            textPreview,
            textToOpen = null,
          } = propertyValuesNormalizer(item.propertyValues)
          return {
            stepTitle: hideSteps === 'Да' ? '' : `Шаг ${i + 1}`,
            title,
            textPreview,
            textToOpen,
            buttonText,
          }
        }),
      },
    })
  }
  if (infoLast) {
    const [{ propertyValues }] = infoLast
    const {
      textPreview,
      textToOpen,
      title = '',
      backgroundColor = '',
      textColor = '',
    } = propertyValuesNormalizer(propertyValues)
    results.push({
      name: 'BlockWarning',
      shortBlock: true,
      data: {
        title,
        textPreview,
        textToOpen,
        backgroundColor,
        textColor,
      },
    })
  }
  if (formLast) {
    results.push({
      name: 'SectionForm',
      title: titleFormLast,
      data: sectionNormalizer(formLast)[0].value,
    })
  }
  return results
}

export const questionAdapter = (element: Element) => {
  const { propertyValuesRelation = [], id, propertyValues = [] } = element
  const elementRelations = propertyValuesRelationNormalizer(
    propertyValuesRelation
  )
  const { regionList } = elementRelations
  if (regionList) {
    const { title } = propertyValuesNormalizer(propertyValues)
    return {
      id,
      isSelectRegion: true,
      tagsData: regionList.map((region) => answerRegionNormalizer(region)),
      title,
    }
  }
  const { answers } = elementRelations
  const { name } = propertyValuesNormalizer(propertyValues)

  const answersData = answers.map((item: Element) => answerNormalizer(item))
  return {
    id,
    title: name,
    tagsData: answersData,
  }
}
