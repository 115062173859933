// @ts-nocheck
import { questionListItemNormalize, questionListNormalize } from './faqAdapter'
import { listNormalizeW } from './sectionListAdapter'
import { Element } from '@/apollo/composition-functions'
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionFaqNavigationAdapter = (data: any) => {
  const { blockTitle, list = [], popularList = [] } = data
  let newList = []
  const newListPop = popularList.map((item: Element) => {
    const list = questionListNormalize(item)
    const { id } = item
    const { title } = { ...listNormalizeW(item) }
    return {
      title,
      value: id,
      list: list.map((item: Element) => questionListItemNormalize(item)),
    }
  })
  if (list.length === 1) {
    const [item] = list
    const _list = questionListNormalize(item)
    newList = _list.map((item: Element) => questionListItemNormalize(item))
  } else {
    newList = list.map((item: Element) => {
      const list = questionListNormalize(item)
      const { id } = item
      const { title } = { ...listNormalizeW(item) }
      return {
        title,
        value: id,
        list: list.map((item: Element) => questionListItemNormalize(item)),
      }
    })
  }
  return {
    withTabs: !!newList && !!newList[0] && !!newList[0].list,
    sectionData: sectionDataAdapter({
      ...data,
      sectionMainTitle: data.title,
    }),
    faqData: [...newListPop, ...newList],
    title: blockTitle,
  }
}
