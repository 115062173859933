// @ts-nocheck
import { v4 as guid } from 'uuid'
import { FormInputType } from '../../../contracts/FormInputType'
import { TypePages } from '../../../contracts/PageTypes'
import {
  propertyValuesNormalizer,
  propertyValuesRelationNormalizer,
} from '../pageAdapter'
import { searchMime } from './sectionDocumentsListAdapter'
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionSearchAdapter = (data: any) => {
  const { textButton, label, placeholder } = data
  return {
    sectionData: sectionDataAdapter(data),
    searchData: {
      btnLabel: textButton,
      isFormSend: false,
      form: {
        id: guid(),
        type: FormInputType.Text,
        label,
        placeholder,
        disabled: false,
        required: false,
        error: false,
        rules: null,
        isDarkTheme: false,
        currentValue: '',
      },
    },
  }
}

export const searchAdapter = (data: any, pagesList: []) => {
  return data.map((item) => {
    const newData = {}
    const {
      propertyValues,
      propertyValuesRelation,
      iblock: { code },
    } = item
    const {
      title = '',
      text = '',
      description = '',
      seoTitle = '',
      seoDescription = '',
    } = propertyValuesNormalizer(propertyValues)

    newData.title = title
    newData.id = item.id

    if (code === 'document') {
      const valuesRelation =
        propertyValuesRelationNormalizer(propertyValuesRelation) || []
      const {
        document: [
          { url: fileUrl = '', size: fileSize = '', contentType = '' } = {},
        ] = [],
      } = valuesRelation
      const mappedSize = !fileSize
        ? fileSize
        : fileSize > 1024 * 1024
        ? Math.round(fileSize / (1024 * 1024)) + ' Мб'
        : Math.round(fileSize / 1024) + ' Кб'
      newData.url = fileUrl
      newData.size = mappedSize
      newData.text = description
      newData.contentType = searchMime(contentType, fileUrl)
    } else if (code === 'pageConstructor') {
      let pageDescription = ''
      if (propertyValuesRelation?.length) {
        const { components } = propertyValuesRelationNormalizer(
          propertyValuesRelation
        )
        const [sectionHero] = components || []
        pageDescription = ((sectionHero.propertyValues || [])[0] || {}).value
      }

      newData.title = seoTitle || item.name
      newData.text = pageDescription || seoDescription
      newData.url = (
        pagesList.find((page) => page.id === item.id) || {}
      ).fullPath
    } else {
      newData.text = text

      pagesList.forEach((page) => {
        if (page.uniquePages === TypePages.news) {
          newData.url =
            page.fullPath.split('/').slice(0, -1).join('/') + `/${item.code}`
        }
      })
    }

    return newData
  })
}
