import { sectionDataAdapter } from "./sectionDataAdapter";
import { propertyValuesNormalizer, propertyValuesRelationNormalizer } from "../pageAdapter";
import { chunk } from 'lodash'
export function sectionGridAdvantagesAdapter(data) {
  const { cardsList = [], details} = data
  return {
    sectionData: sectionDataAdapter(data),
    cardsList: cardsList.map((card) => {
      const { title = '', description = '', details= '' } = propertyValuesNormalizer(card.propertyValues)
      const { image = [{ url: null }] } = propertyValuesRelationNormalizer(card.propertyValuesRelation)
      return {
        image: image[0].url,
        title,
        description,
      }
    }),
    details,
  }
}
