// @ts-nocheck
import {
  propertyValuesRelationNormalizer,
  propertyValuesNormalizer,
} from '../pageAdapter'
import { Element } from '@/apollo/composition-functions'

export const sectionPopularAdapter = (data: any, extended?: any) => {
  const { blockPopular = [], sectionId } = data
  let newList = []
  let newText = ''
  let sectionMainTitle = ''
  let sectionSideTitle = 'Популярное'
  if (blockPopular?.length === 1) {
    const [item] = blockPopular
    const _list = listNormalize(item)
    const { blockPopularTitle } = listNormalizeW(item)
    sectionMainTitle = blockPopularTitle
    newList = _list.map((item: Element) => listItemNormalize(item))
  }

  return {
    list: newList,
    text: newText,
    isExtended: extended,
    typeLink: '',
    sectionData: {
      sectionMainTitle,
      sectionSideTitle,
      sectionId,
    },
  }
}

export const normalizeProps = (
  propertyValues: any,
  propertyValuesRelation: any,
  propertyValuesEnum: any
) => {
  return {
    ...propertyValuesNormalizer(propertyValues),
    ...propertyValuesNormalizer(propertyValuesEnum),
    ...propertyValuesRelationNormalizer(propertyValuesRelation),
  }
}

export const listNormalizeW = (listItem: Element) => {
  const { propertyValues, propertyValuesRelation, propertyValuesEnum } =
    listItem
  const {
    blockPopularTitle = '',
    text = '',
    list = [],
  } = {
    ...normalizeProps(
      propertyValues,
      propertyValuesRelation,
      propertyValuesEnum
    ),
  }
  return { blockPopularTitle, list, text }
}

export const listNormalize = (listItem: Element) => {
  const { propertyValues, propertyValuesRelation, propertyValuesEnum } =
    listItem
  const { blockPopularElements = [] } = {
    ...normalizeProps(
      propertyValues,
      propertyValuesRelation,
      propertyValuesEnum
    ),
  }
  return blockPopularElements
}

export const listItemNormalize = (item: Element) => {
  const { propertyValues, propertyValuesEnum, propertyValuesRelation, code } =
    item
  const value = normalizeProps(
    propertyValues,
    propertyValuesRelation,
    propertyValuesEnum
  )
  return { ...value, code }
}
