import { propertyValuesNormalizer } from '../pageAdapter'
import { Element } from '../../composition-functions'
import { Tag } from "@/contracts/Tags";
export const tagsAdapter = (tags: Element[]) => {
  if (!tags) return []
  return (
    tags.map((tag: any) => {
      const { id, name, propertyValues, propertyValuesEnum } = tag
      const properties: Tag = propertyValuesNormalizer([
        ...propertyValues,
        ...propertyValuesEnum,
      ])
      return {
        id,
        name,
        ...properties,
      }
    })
  )
}
