// @ts-nocheck
import {
  propertyValuesNormalizer,
  propertyValuesRelationNormalizer,
} from '../pageAdapter'
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionNewsAdapter = (data: any) => {
  const { title } = data

  return {
    title,
    sectionData: sectionDataAdapter(data),
  }
}

export const newsAdapter = (data: any) => {
  return data.map((item) => {
    const { activeFrom: date, propertyValues, propertyValuesRelation, seo } = item
    const { title = '', text = '' } = propertyValuesNormalizer(propertyValues)
    const valuesRelation =
      propertyValuesRelationNormalizer(propertyValuesRelation).tags || []
    const tags = valuesRelation.map((tag) =>
      propertyValuesNormalizer(tag.propertyValues)
    )
    return {
      title,
      date,
      text,
      seo,
      id: item.id,
      tags,
      url: `/${item.code}`,
      code: item.code,
    }
  })
}
