// @ts-nocheck
import { propertyValuesNormalizer } from '../pageAdapter'
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionBannerAdapter = (data: any) => {
  const { banner: [{ propertyValues = [] } = {}] = [] } = data
  const bannerData = propertyValuesNormalizer(propertyValues)
  return {
    sectionData: sectionDataAdapter(data),
    title: bannerData.title,
    textPreview: bannerData.textPreview,
    textToOpen: bannerData.textOpen,
  }
}
