// @ts-nocheck
import { Element } from '../../composition-functions'

import {
  propertyValuesNormalizer,
  propertyValuesRelationNormalizer,
} from '../pageAdapter'
import { listNormalizeW } from './sectionListAdapter'
import { documentListItemNormalize } from './sectionDocumentsListAdapter'
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionFaqAdapter = (data: any) => {
  const { list = [], documentList = [] } = data
  let newList = []
  if ([...(documentList || []), ...(list || [])].length === 1) {
    const [item] = list
    const _list = questionListNormalize(item)
    newList = _list.map((item: Element) => questionListItemNormalize(item))
  } else {
    newList = list.map((item: Element) => {
      const __list = questionListNormalize(item)
      const { id } = item
      const { title } = { ...listNormalizeW(item) }
      return {
        tabTitle: title,
        value: id,
        list: __list.map((item: Element) => questionListItemNormalize(item)),
      }
    })
    if (documentList?.length) {
      newList = [
        ...newList,
        ...documentList.map((documents: Element) => {
          const {
            propertyValuesRelation,
            propertyValues,
            id,
            propertyValuesEnum,
          } = documents
          const {
            list: documentsList = [],
            title = 'Документы',
            reverse = '',
          } = {
            ...propertyValuesRelationNormalizer(propertyValuesRelation),
            ...propertyValuesNormalizer(propertyValues),
            ...propertyValuesNormalizer(propertyValuesEnum),
          }
          return {
            tabTitle: title,
            isDocument: true,
            value: id,
            list: reverse
              ? documentsList.map(documentListItemNormalize).reverse()
              : documentsList.map(documentListItemNormalize),
          }
        }),
      ]
    }
  }
  return {
    withTabs: !!newList && !!newList[0] && !!newList[0].list,
    sectionData: sectionDataAdapter({
      ...data,
      sectionSideTitle: data.tag,
      sectionMainTitle: data.title,
    }),
    list: newList,
  }
}
export const normalizeProps = (
  propertyValues: any,
  propertyValuesRelation: any,
  propertyValuesEnum: any
) => {
  return {
    ...propertyValuesNormalizer(propertyValues),
    ...propertyValuesRelationNormalizer(propertyValuesRelation),
    ...propertyValuesNormalizer(propertyValuesEnum),
  }
}

export const questionListNormalize = (listItem: Element) => {
  const { propertyValues, propertyValuesRelation, propertyValuesEnum } =
    listItem
  const { reverse, sortActiveDate } =
    propertyValuesNormalizer(propertyValuesEnum)
  let { list = [] } = {
    ...normalizeProps(
      propertyValues,
      propertyValuesRelation,
      propertyValuesEnum
    ),
  }
  if (sortActiveDate) {
    list = list.sort((a, b) => {
      if (new Date(a.activeFrom).getTime() > new Date(b.activeFrom).getTime())
        return -1
      else if (
        new Date(a.activeFrom).getTime() < new Date(b.activeFrom).getTime()
      )
        return 1
      return 0
    })
  }
  return reverse ? list.reverse() : list
}

export const questionListItemNormalize = (item: Element) => {
  const { id, propertyValues, propertyValuesRelation, propertyValuesEnum } =
    item
  const value = normalizeProps(
    propertyValues,
    propertyValuesRelation,
    propertyValuesEnum
  )
  return {
    id,
    ...value,
  }
}
