// @ts-nocheck
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionInsuranceCaseAdapter = (data: any) => {
  const {
    kaskoNote,
    kaskoGlasses,
    vehicleCantMove,
    headerKaskoBusines,
    bodyKaskoBusines,
    osagoFewVehicle,
    osagoAnotherInsurer,
    osagoCauserNotHave,
    osagoNotOnlyVehicle,
    infoWorryBody,
    infoWorryHeader,
    titleOsopo,
    bodyOsopo
  } = data

  return {
    sectionData: sectionDataAdapter(data),
    info: {
      kaskoNote,
      kaskoGlasses,
      vehicleCantMove,
      kaskoBusines: {
        title: headerKaskoBusines,
        body: bodyKaskoBusines,
      },
      osopo : {
        title: titleOsopo,
        body: bodyOsopo,
      },
      osagoFewVehicle,
      osagoAnotherInsurer,
      osagoCauserNotHave,
      osagoNotOnlyVehicle,
      worry: {
        title: infoWorryHeader,
        body: infoWorryBody,
      },
    },
  }
}
