import { sectionDataAdapter } from "./sectionDataAdapter";
import { buttonsAdapter } from "./buttonsAdapter";

export function sectionOsagoNumberAdapter(data) {
  return {
    sectionData: sectionDataAdapter(data),
    actionButtons: buttonsAdapter(data.actionButtons),
    title: data.title,
  }
}
