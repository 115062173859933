// @ts-nocheck
import {
  propertyValuesRelationNormalizer,
  propertyValuesNormalizer,
} from '../pageAdapter'
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionListExtendedAdapter = (data: any, extended?: any) => {
  const {
    list: [{ propertyValuesRelation = [] } = {}] = [],
  } = data
  const value = propertyValuesRelationNormalizer(propertyValuesRelation)
  const list = value?.list?.map((item) => {
    return {
      ...propertyValuesNormalizer(item.propertyValues),
      ...propertyValuesNormalizer(item.propertyValuesEnum),
    }
  }) || []
  return {
    list,
    isExtended: extended,
    sectionData: sectionDataAdapter(data),
  }
}
