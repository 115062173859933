import { sectionDataAdapter } from "./sectionDataAdapter";
import { buttonsAdapter } from "./buttonsAdapter";

export function sectionButtonsAdapter(data: any){
  return {
    sectionData: sectionDataAdapter(data),
    actionButtons: buttonsAdapter(data.actionButtons),
    countInRow: Number.parseInt(data.countInRow) || 3,
    spaceBetweenButtons: data.spaceBetweenButtons || '0.4rem',
    actionButtonsAlignment: data.actionButtonsAlignment,
  }
}
