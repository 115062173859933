// @ts-nocheck
import {
  propertyValuesNormalizer,
  propertyValuesRelationNormalizer,
} from '../pageAdapter'
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionBannerListAdapter = (data: any) => {
  const { list } = data
  const value = list.map((item) => {
    const mappedItem = propertyValuesNormalizer(item.propertyValues)
    const mappedItemRelation = propertyValuesRelationNormalizer(
      item.propertyValuesRelation
    )
    const {
      blockListData: [
        {
          propertyValuesRelation: blockListRelation = [],
          propertyValues: blockListValues = [],
        } = {},
      ] = [],
    } = mappedItemRelation
    const title = propertyValuesNormalizer(blockListValues)
    const blockListValuesRelation =
      propertyValuesRelationNormalizer(blockListRelation)
    const { list = [] } = blockListValuesRelation
    const blockList = list.map((item) =>
      propertyValuesNormalizer(item.propertyValues)
    )
    return {
      title: mappedItem.title,
      tabTitle: item.name,
      value: item.id,
      data: {
        title: mappedItem.title,
        textPreview: mappedItem.textPreview,
        textToOpen: mappedItem.textOpen,
        isAngle: true,
        blockListTitle: title.title,
        blockListData: blockList,
      },
    }
  })

  return {
    sectionData: sectionDataAdapter(data),
    list: value,
  }
}
