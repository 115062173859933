// @ts-nocheck
export default (data: any) => {
  return {
    sectionData: {
      sectionId: data.sectionId,
      sectionMainTitle: data.title || 'Адреса офисов продаж',
      sectionStyle: data.style,
      sectionSideTitle: data.style !== 'Да' ? data.tag : null,
    },
  }
}
