// @ts-nocheck
import { Element } from '../../composition-functions'
import { questionListNormalize } from './faqAdapter'
import { documentListItemNormalize } from './sectionDocumentsListAdapter'
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionDocumentsAdapter = (data: any) => {
  const {
    text,
    titleText,
    titleCards,
    list,
  } = data
  let newList = []
  const [item] = list
  const _list = questionListNormalize(item)

  newList = _list.map((item: Element) => documentListItemNormalize(item))
  return {
    list: newList,
    titleCards,
    titleText,
    text,
    sectionData: sectionDataAdapter(data),
  }
}
