import { sectionDataAdapter } from "./sectionDataAdapter";
import { buttonsAdapter } from "./buttonsAdapter";

export function sectionTextActionsAdapter(data: any) {
  return {
    sectionData: sectionDataAdapter(data),
    actionButtons: buttonsAdapter(data.actionButtons),
    description: data.description,
    hidePaddingBottom: data.hidePaddingBottom === 'Да'
  }
}
