// @ts-nocheck
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionQuizAdapter = (data: any) => {
  const {
    'id-idRecipients': idRecipients,
    quizType,
    successMessage,
  } = data
  return {
    sectionData: sectionDataAdapter(data),
    idRecipients,
    quizType,
    successMessage,
  }
}
