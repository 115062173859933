// @ts-nocheck
import {
  propertyValuesRelationNormalizer,
  propertyValuesNormalizer,
} from '../pageAdapter'
import { questionListItemNormalize, questionListNormalize } from './faqAdapter'
import { listNormalizeW } from './sectionListAdapter'
import { sectionDocumentsAdapter } from './sectionDocumentsAdapter'
import { Element } from '@/apollo/composition-functions'

export const sectionListBlockLinksAdapter = (data: any) => {
  const {
    isWithTabs,
    sectionMainTitle,
    sectionSideTitle,
    sectionId,
    lastModified,
    list,
    docsList,
  } = data
  let docsData
  if (docsList) {
    const [{ propertyValues, propertyValuesRelation, propertyValuesEnum }] =
      docsList
    const normDocData = {
      ...propertyValuesNormalizer(propertyValues),
      ...propertyValuesNormalizer(propertyValuesEnum),
      ...propertyValuesRelationNormalizer(propertyValuesRelation),
    }
    docsData = sectionDocumentsAdapter(normDocData)
  }

  const newList = list.map((item: Element) => {
    const list = questionListNormalize(item)
    const { id } = item
    const { title } = { ...listNormalizeW(item) }
    return {
      tabTitle: title,
      value: id,
      list: list.map((item: Element) => questionListItemNormalize(item)),
    }
  })
  return {
    list: newList,
    title: sectionMainTitle,
    lastModified,
    sectionData: { sectionSideTitle, sectionId },
    isWithTabs: isWithTabs === 'Да',
    docsData,
  }
}
