import { propertyValuesNormalizer } from "../pageAdapter";
import { TCardAchievement } from "../../../contracts/Cards";
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionAchievementsAdapter = (data: any) => {
  const {
    sectionSideTitle,
    cardList = [],
    sizes = '',
    background = '',
  } = data
  const arrayOfSizes: number[] = sizes ? sizes.split(',').map((num) => JSON.parse(num)) : []

  const mappedCardList: TCardAchievement[] = cardList.map((card, index) => {
    const { background, value, subtitle, iconColor } = propertyValuesNormalizer(card.propertyValues)
    const { hideIcons } = propertyValuesNormalizer(card.propertyValuesEnum)
    return {
      background,
      value,
      subtitle,
      iconColor,
      hideIcons: hideIcons === 'Да',
      col: arrayOfSizes[index] || (sectionSideTitle ? 4 : 3),
      sm: 2,
    }
  })
  return {
    sectionData: sectionDataAdapter(data),
    background,
    cardList: mappedCardList,
  }
}
