// @ts-nocheck
import { Element } from '../../composition-functions'
import { propertyValuesNormalizer } from '../pageAdapter'
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionCardsInfoAdapter = (data: any) => {
  const { list } = data
  return {
    sectionData: sectionDataAdapter({
      ...data,
      sectionMainTitle: data.title,
      sectionSideTitle: data.tag
    }),
    list: list.map((item: Element) => {
      const { propertyValues, id } = item
      const { list, listDescription, title, secondTitle } =
        propertyValuesNormalizer(propertyValues)
      return {
        id,
        secondTitle,
        title,
        list: list.map((item: string, i: number) => ({
          title: item,
          description: listDescription[i],
        })),
      }
    }),
  }
}
