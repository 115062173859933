// @ts-nocheck
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionSubscribeAdapter = (data: any) => {
  const { successMessage } = data
  return {
    sectionData: sectionDataAdapter(data),
    successMessage,
  }
}
