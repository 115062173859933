// @ts-nocheck
import { buttonsAdapter } from './'
export const sectionHeroVipAdapter = (data: any) => {
  const {
    sectionMainTitle,
    text,
    alt,
    imageDesktop: [{ url: imageDesktop = null } = {}] = [{}],
    imageMobile: [{ url: imageMobile = null } = {}] = [{}],
    buttonActions,
  } = data
  return {
    sectionMainTitle,
    text,
    imageDesktop,
    imageMobile,
    alt,
    buttonActions: buttonsAdapter(buttonActions),
  }
}
