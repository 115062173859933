import {
  propertyValuesNormalizer,
  propertyValuesRelationNormalizer,
} from '../pageAdapter'
import { buttonsAdapter } from './buttonsAdapter'

function supportLinkAdapter(link: any) {
  const {
    title,
    description,
    link: linkUrl,
  } = propertyValuesNormalizer(link.propertyValues)
  const { icon: [{ url: icon = '' } = {}] = [] } =
    propertyValuesRelationNormalizer(link.propertyValuesRelation)
  return {
    title,
    description,
    link: linkUrl,
    icon,
  }
}

const SUPPORT_CARD_ALIGNMENTS = {
  Слева: 'flex-start',
  'По центру': 'center',
  Справа: 'flex-end',
}

function supportCardAdapter(card: any) {
  const { title, description } = propertyValuesNormalizer(card.propertyValues)
  const { contentAlign } = propertyValuesNormalizer(card.propertyValuesEnum)
  const {
    actionButtons,
    iconMobile: [{ url: iconMobileUrl = '' } = {}] = [],
    imageMobile: [{ url: imageMobileUrl = '' } = {}] = [],
    image: [{ url: imageUrl = '' } = {}] = [],
  } = propertyValuesRelationNormalizer(card.propertyValuesRelation)
  return {
    title,
    description,
    iconMobileUrl,
    imageMobileUrl,
    imageUrl,
    contentAlign: SUPPORT_CARD_ALIGNMENTS[contentAlign],
    actionButtons: buttonsAdapter(actionButtons),
  }
}

export function sectionSupportAdapter(data: any) {
  return {
    title: data.title,
    firstBlockTitle: data.firstBlockTitle,
    secondBlockTitle: data.secondBlockTitle,
    linksTitle: data.linksTitle,
    firstBlockCards: data.firstBlockCards.map(supportCardAdapter),
    secondBlockCards: data.secondBlockCards.map(supportCardAdapter),
    links: data.links.map(supportLinkAdapter),
    chipsContent: {
      faqLink: data.faqLink || '',
      certificatePaymentLink: data.certificatePaymentLink || '',
      police: {
        policeTitle: data.policeTitle || '',
        policeDescription: data.policeDescription || '',
        policeLinks: data.policeLinks.map(supportLinkAdapter) || [],
      },
      kbm: {
        title: data.osagoKbmTitle || '',
        description: data.osagoKbmDescription || '',
        buttons: data.osagoKbmLinks.map(supportLinkAdapter) || [],
      },
      policeOsago: {
        title: data.osagoPoliceTitle || '',
        description: data.osagoPoliceDescription || '',
        buttons: buttonsAdapter(data.osagoPoliceButtons) || [],
      },
      discardOsago: {
        title: data.osagoDiscardTitle || '',
        description: data.osagoDiscardDescription || '',
        buttons: buttonsAdapter(data.osagoDiscardButtons) || [],
      },
    },
  }
}
