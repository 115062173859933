// @ts-nocheck
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionFeedbackLKAdapter = (data: any) => {
  const {
    headerResponseAppeal,
    bodyResponseAppeal,
    headerDocuments,
    bodyDocuments,
  } = data
  return {
    sectionData: sectionDataAdapter(data),
    info: {
      responseAppeal: {
        title: headerResponseAppeal,
        body: bodyResponseAppeal,
      },
      documents: {
        title: headerDocuments,
        body: bodyDocuments,
      },
    },
  }
}
