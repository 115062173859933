// @ts-nocheck
import { propertyValuesRelationNormalizer } from '../pageAdapter'
import { cardsDataItemNormalize } from '../modules/sectionCardImageAdapter'
import { sectionDataAdapter } from "./sectionDataAdapter";
import { buttonsAdapter } from "./buttonsAdapter";

export const sectionCardIconAdapter = (data: any) => {
  const {
    col,
    clipAngles,
    list: [{ propertyValuesRelation = [] } = {}] = [],
  } = data
  const newCol = col?.split(',').map((itemCol) => Number(itemCol)) || []
  const value = propertyValuesRelationNormalizer(propertyValuesRelation)
  const list = value.list?.map((item, index) => {
    const cardValues = cardsDataItemNormalize(item)
    const { iconLink: [{ url: iconLink } = {}] = [], actionButtons } = cardValues
    return {
      col: newCol[index] || 3,
      ...cardValues,
      iconLink,
      actionButtons: buttonsAdapter(actionButtons),
    }
  })

  return {
    sectionData: sectionDataAdapter(data),
    clipAngles: clipAngles === 'Да',
    list: list || [],
  }
}
