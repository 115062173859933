import { cardsDataItemNormalize } from './sectionCardImageAdapter'
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionReviewsAdapter = (data) => {
  const { reviewsList = [], countReviewsAtSlide = '', alternate } = data

  return {
    sectionData: sectionDataAdapter(data),
    alternate: alternate === 'Да',
    countReviewsAtSlide: countReviewsAtSlide ? JSON.parse(countReviewsAtSlide) : 0,
    reviewsList: reviewsList.map((review) => {
      const reviewValues = cardsDataItemNormalize(review)
      const {
        title,
        description,
        reviewDate,
        estimate,
        nameReviewAuthor,
        linkReviewAuthor,
        countLines,
        backgroundColor,
        imageReviewAuthor: [{ url: imageReviewAuthor } = {}] = [],
      } = reviewValues
      return {
        title,
        description,
        reviewDate,
        estimate,
        countLines,
        backgroundColor,
        nameReviewAuthor,
        linkReviewAuthor,
        imageReviewAuthor,
      }
    }),
  }
}
