// @ts-nocheck
import {
  propertyValuesRelationNormalizer,
  propertyValuesNormalizer,
} from '../pageAdapter'
import { Element } from '../../composition-functions'
import { HOVER_COLORS } from "../../../contracts/Cards";
import { buttonsAdapter } from "./buttonsAdapter";
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionCardImageAdapter = (data: any) => {
  const { list = [] } = data
  const cardsData = list.map((item: Element) => {
    const {
      list,
      title,
      url,
      value,
      sizes,
      sizesMobile,
      actionButtons,
      actionButtonsAlignment,
    } = cardsDataNormalize(item)
    const { id } = item
    const spitedCol = sizes.split(',')
    const spitedColSm = sizesMobile.split(',')
    const cardSizesSm = spitedColSm && spitedColSm[0] ? spitedColSm.map((num) => JSON.parse(num)) : []
    const newCol = spitedCol && spitedCol[0] ? spitedCol.map((num) => JSON.parse(num)) : []
    return {
      tabTitle: title,
      value: id,
      actionButtons: buttonsAdapter(actionButtons),
      actionButtonsAlignment,
      link: { url, value, isArrow: true },
      cardsData: list.map((item: Element, index: number) => {
        const mappedItem = cardsDataItemNormalize(item)
        const {
          image: [{ url: image = '' } = {}] = [],
          imageRectangleFirst: [{ url: imageRectangleFirst = '' } = {}] = [],
          imageRectangleSecond: [{ url: imageRectangleSecond = '' } = {}] = [],
          alt = '',
          hasBorder,
          hasCoverBg,
          hover,
          actionButtons: cardActionButtons
        } = mappedItem
        return {
          title: mappedItem.title,
          description: mappedItem.description,
          col: newCol[index],
          sm: cardSizesSm[index],
          url: mappedItem.url,
          textLink: mappedItem.textLink,
          hasBorder: hasBorder === 'Да',
          hasCoverBg: hasCoverBg === 'Да',
          hover: HOVER_COLORS[hover],
          actionButtons: buttonsAdapter(cardActionButtons),
          image: {
            url: image,
            alt,
          },
          imageRectangleFirst: {
            url: imageRectangleFirst,
            alt,
          },
          imageRectangleSecond: {
            url: imageRectangleSecond,
            alt,
          },
        }
      }),
    }
  })

  const withTabs = !(cardsData.length === 1)

  const setCol = (item: any, col: number, md: number, sm: number) => {
    item.forEach((card: any) => {
      card.col = card.col || col
      card.md = card.col ? (8 * card.col) / 12 : md
      card.sm = card.sm || sm
    })
  }

  if (withTabs) {
    const listCards = cardsData.map((item) => item.cardsData)
    listCards.forEach((cards, index: number) => {
      const hasSmValue = cards[cards.length - 1]?.sm
      const cardsDataLocal = cards
      const cardsDataLength = cardsDataLocal.length
      if (cardsDataLength >= 3) {
        setCol(cardsDataLocal, 4, 4, 1)
      } else {
        setCol(cardsDataLocal, 12 / cardsDataLength, 12 / cardsDataLength, 1)
      }
      if (!hasSmValue && cardsDataLength % 2 !== 0) {
        cardsDataLocal[cardsDataLength - 1].sm = 2
      }
      cardsData[index].cardsData = cardsDataLocal
    })
  }

  const cardsDataLocal = cardsData[0].cardsData
  const cardsDataLength = cardsDataLocal.length
  if (cardsDataLength > 3) {
    setCol(cardsDataLocal, 4, 4, 1)
  } else {
    setCol(cardsDataLocal, 12 / cardsDataLength, 12 / cardsDataLength, 1)
  }
  if (cardsDataLength % 2 !== 0) {
    cardsDataLocal[cardsDataLength - 1].sm = 2
  }
  return {
    sectionData: sectionDataAdapter(data),
    cardsData,
    withTabs,
    titleNoMargin: withTabs,
  }
}

export const normalizeProps = (
  propertyValues: any,
  propertyValuesRelation: any,
  propertyValuesEnum: any,
) => {
  return {
    ...propertyValuesNormalizer(propertyValues),
    ...propertyValuesNormalizer(propertyValuesEnum),
    ...propertyValuesRelationNormalizer(propertyValuesRelation),
  }
}

export const cardsDataNormalize = (item: Element) => {
  const { name, id, propertyValues, propertyValuesRelation, propertyValuesEnum } = item
  const {
    list = [],
    title = '',
    url = '',
    value = '',
    sizes = '',
    sizesMobile = '',
    actionButtons,
    actionButtonsAlignment,
  } = { ...normalizeProps(propertyValues, propertyValuesRelation, propertyValuesEnum) }
  return { list, title, url, value, sizes, sizesMobile, actionButtons, actionButtonsAlignment }
}
export const cardsDataItemNormalize = (item: Element) => {
  const { id, propertyValues, propertyValuesRelation, propertyValuesEnum } = item
  const value = normalizeProps(propertyValues, propertyValuesRelation, propertyValuesEnum)
  return {
    id,
    ...value,
  }
}
