export const dateAdapter = (
  date: string,
  withMonth: boolean = false
): string => {
  return date
    ? new Date(date).toLocaleDateString(
        'ru',
        withMonth
          ? {
              month: 'long',
              day: 'numeric',
              year: 'numeric',
              timeZone: 'UTC',
            }
          : {
              timeZone: 'UTC',
            }
      )
    : ''
}
