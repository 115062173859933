// @ts-nocheck
import { propertyValuesRelationNormalizer } from '../pageAdapter'

export const listVacanciesAdapter = (data: any) => {
  if (!data) return []
  return data.map((item) => {
    const { name, code, propertyValuesRelation } = item
    const {
      region = [],
      schedule,
      specialization,
    } = propertyValuesRelationNormalizer(propertyValuesRelation)
    const regionName = region && region[0]?.name
    const scheduleName = schedule && schedule[0]?.name
    const tags = []
    if (regionName) {
      tags.push(regionName)
    }
    if (scheduleName) {
      tags.push(scheduleName)
    }
    const specializationName = specialization && specialization[0]?.name
    return {
      specializationName,
      name,
      tags,
      code,
    }
  })
}
