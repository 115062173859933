// @ts-nocheck
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionAppealAdapter = (data: any) => {
  const {
    headerAboutKBM,
    bodyAboutKBM,
    headerKBMValue,
    bodyKBMValue,
    headerKBMFix,
    bodyKBMFix,
    headerAutoAnswer,
    bodyAutoAnswer,
    headerKBMHow,
    bodyKBMHow,
    headerResponseAppeal,
    bodyResponseAppeal,
    bodyDocuments,
    headerDocuments,
    bodyRegomendAppeal,
    headerRegomendAppeal,
    bodyAppealOptions,
    headerAppealOptions,
    showCertificateMedicalPayment,
    privacyTitle,
    privacyLinkUrl,
    privacyLinkLabel,
  } = data

  return {
    sectionData: sectionDataAdapter(data),
    showCertificateMedicalPayment: showCertificateMedicalPayment === 'Да',
    privacyTitle,
    privacyLinkUrl,
    privacyLinkLabel,
    info: {
      aboutKbm: {
        title: headerAboutKBM,
        body: bodyAboutKBM,
      },
      valueKbm: {
        title: headerKBMValue,
        body: bodyKBMValue,
      },
      KBMHow: {
        title: headerKBMHow,
        body: bodyKBMHow,
      },
      correctKbm: {
        title: headerKBMFix,
        body: bodyKBMFix,
      },
      autoAnswer: {
        title: headerAutoAnswer,
        body: bodyAutoAnswer,
      },
      regomendAppeal: {
        title: headerRegomendAppeal,
        body: bodyRegomendAppeal,
      },
      responseAppeal: {
        title: headerResponseAppeal,
        body: bodyResponseAppeal,
      },
      documents: {
        title: headerDocuments,
        body: bodyDocuments,
      },
      appealOptions: {
        title: headerAppealOptions,
        body: bodyAppealOptions,
      }
    },
  }
}
