import { sectionDataAdapter } from "./sectionDataAdapter";
import { cardsDataItemNormalize } from "./sectionCardImageAdapter";
import { buttonsAdapter } from "./buttonsAdapter";

export function sectionCardsTitleAndIconCardAdapter(card) {
  const {
    title,
    titleColor,
    actionButtons,
    icon: [{ url: icon = '' } = {}] = [],
    backgroundColor,
  } = cardsDataItemNormalize(card)
  return {
    title,
    titleColor,
    actionButtons: buttonsAdapter(actionButtons),
    icon,
    backgroundColor,
  }
}

export function sectionCardsTitleAndIconAdapter(data) {
  let cols: number[] = []
  if (data.cols) {
    cols = data.cols.split(',').map((colNumber) => JSON.parse(colNumber))
  } else {
    cols = data.cards.map(() => 12 / data.cards.length)
  }
  return {
    sectionData: sectionDataAdapter(data),
    cards: data.cards.map(sectionCardsTitleAndIconCardAdapter),
    cols,
  }
}
