// @ts-nocheck
import {
  propertyValuesNormalizer,
  propertyValuesRelationNormalizer,
} from '../pageAdapter'
import { buttonsAdapter } from "./buttonsAdapter";
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionImageTextAdapter = (data: any) => {
  const { list } = data
  const newList = list.map((item) => {
    const { id, name, propertyValues, propertyValuesRelation, propertyValuesEnum } = item
    const { text, altImage } = propertyValuesNormalizer(propertyValues)
    const { actionButtonsAlignment } = propertyValuesNormalizer(propertyValuesEnum)
    const {
      imageDesktop: [{ url: imageDesktop } = {}] = [],
      imageMobile: [{ url: imageMobile } = {}] = [],
      actionButtons,
    } = propertyValuesRelationNormalizer(propertyValuesRelation)
    return {
      value: id,
      tabTitle: name,
      text,
      altImage,
      imageDesktop,
      imageMobile,
      actionButtons: buttonsAdapter(actionButtons),
      actionButtonsAlignment,
    }
  })

  return {
    sectionData: sectionDataAdapter(data),
    list: newList,
  }
}
