// @ts-nocheck
import {
  propertyValuesRelationNormalizer,
  propertyValuesNormalizer,
} from '../pageAdapter'
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionLeadsAdapter = (data: any) => {
  const {
    list = [],
    textData: [{ propertyValues: textDataPropertyValues = [] } = {}] = [],
  } = data
  const textData = propertyValuesNormalizer(textDataPropertyValues)
  const listsValues = list.map((item) => {
    return {
      list: propertyValuesRelationNormalizer(
        item.propertyValuesRelation
      ).list.map((block) => {
        const { propertyValuesRelation: listPropertyValuesRelation = [] } =
          block
        const { propertyValues: listPropertyValues = [] } = block
        const mappedItem = propertyValuesNormalizer(listPropertyValues)
        const mappedItemValuesRelation = propertyValuesRelationNormalizer(
          listPropertyValuesRelation
        )
        mappedItem.linkUrl = block.code
        mappedItem.linkAppend = true
        const { image: [{ url: image = '' } = {}] = [] } =
          mappedItemValuesRelation
        return {
          ...mappedItem,
          image,
        }
      }),
      title: propertyValuesNormalizer(item.propertyValues).title,
    }
  })
  return {
    sectionData: sectionDataAdapter(data),
    textData: textData.text,
    list: listsValues,
  }
}
