// @ts-nocheck
import { sectionDataAdapter } from "./sectionDataAdapter";

export const sectionCustomAdapter = (data: any) => {
  const {
    customCode,
    isLayoutStyles = 'Нет'
  } = data
  return {
    isLayoutStyles: isLayoutStyles === 'Да',
    sectionData: {
      ...sectionDataAdapter(data),
      customCode,
    }
  }
}
